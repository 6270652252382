import { TextField as MUITextField, Button, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';
import SaveCancelButtonGroup from './SaveCancelButtonGroup';

const GroupEditorToolbar = (props) => {
  const { responseFetched, groupName, updateValues, updateIndex, handleFileInputChange, getJsonDownload } = props;
  const navigate = useNavigate();

  return (
    <div
      className='row'
      style={{
        display:'flex',
        justifyContent:'space-between',
        flexWrap:'wrap',
        gap:20,
        width:"100%"
      }}
    >
      <div style={{display:'flex', gap:12, alignItems:'center'}}>
        <ArrowCircleLeftIcon
          fontSize="large"
          color="secondary"
          onClick={() => navigate(-1)}
          sx={{
            "&:hover": {
              color: "#103996",
              cursor: "pointer",
            }
          }}
        />
        <MUITextField
          id="outlined-basic"
          label="Group Name"
          variant="outlined"
          value={responseFetched ? groupName : ""}
          sx={{
            background: "#fff",
          }}
          type="text"
          disabled
        />
      </div>
      <div
        className="all-buttons"
        style={{
          display: "flex",
          gap:10
        }}
      >
        <div>

          {(updateValues.length > 0) ?
            <SaveCancelButtonGroup
              onSave={() => {
                updateIndex()
              }}
              onCancel={() => {
                navigate(0)
              }} />
            :
            ""
          }
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <Tooltip title="Add Level JSON">
            <>
              <label htmlFor="upload-json">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  style={{
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "10px",
                    height: "50px",
                    width: 'max-content'
                  }}
                >
                  Level JSON
                </Button>
              </label>
              <input
                accept=".json"
                style={{
                  display: 'none',
                  background: "none"
                }}
                id="upload-json"
                type="file"
                onChange={handleFileInputChange}
              />
            </>
          </Tooltip>

          <Button
            variant="contained"
            component="span"
            startIcon={<FileDownloadIcon />}
            onClick={getJsonDownload}
            style={{
              border: "none",
              cursor: "pointer",
              marginLeft: "10px",
              height: "50px",
              width: 'max-content'
            }}
          >
            Level json
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GroupEditorToolbar
export default function YarlNavigationPageStyles() {
  return (
      <style>
          {`
          .yarl__navigation_prev{
              display: none !important;
          }
          .yarl__navigation_next{
              display: none !important;
          }
      `}
      </style>
  )
}

export const initialFormData = {
  android_version: "",
  ios_version: ""
}


export const initialAppStoreFormData = {
  android_store_version: "",
  ios_store_version: ""
}

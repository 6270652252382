import { useContext } from 'react'
import { Field } from 'react-final-form'
import { MEDIA_DELETE } from '../constants';
import { store } from '../store';
import { DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { ShowErrorAlert } from '../store/alerts';
import { postRequest } from '../data/helpers/services';
import { AccessTokenContext } from '../routes';
import { s3Client, S3_BUCKET } from "../constants/s3Bucket"
import ZipUploadThumbnail from './ZipUploadThumbnail';
import ZipUploadPreviewBox from './ZipUploadPreviewBox';

function ZipUpload(props: any) {
    const { dispatch } = store;

    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const { zipObj, setZipObj, groupId } = props;
    const uploadFile = async (file) => {

        let filePath = file.name;
        let allowedExtensions = /(\.zip)$/i;
        if (!allowedExtensions.exec(filePath)) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Please upload file having extensions .zip only."
                })
            )
            return false
        }

        if (file.name.includes(" ")) {
            dispatch(ShowErrorAlert({ visible: true, message: "Zip file name contains spaces kindly remove" }));
            return false
        }
        let folder = `${file.name}`
        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: ("tiny_learner/group/" + folder),
            ContentType: 'application/zip',
        }

        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                zipObj.media_name = `${file.name}`
                setZipObj({ ...zipObj, media: folder, uploaded: true, uploadStarted: false })
            }
        }
        catch (err) {
            console.log(err)
        }

        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };

    const handleFileRemove = async (event: any) => {
        const media_path = "tiny_learner/group/" + zipObj.media;
        const input = {
            Bucket: S3_BUCKET,
            Key: media_path
        }
        const command = new DeleteObjectCommand(input);
        const status = await s3Client.send(command);

        const res = await postRequest(
            MEDIA_DELETE,
            {
                "id": groupId,
                "media_type": "TL_GP"   // Tiny learner group
            },
            accessToken
        );
        zipObj.uploaded = false;
        setZipObj({ ...zipObj, uploadStarted: false, uploaded: false, media: "" })
    }

    let group_no = groupId;
    if (!group_no || group_no === null || group_no === "undefined") {
        group_no = "";
    }

    const renderZipUploadContainer = () => {
        if (!zipObj.uploaded) {
            return <ZipUploadThumbnail handleFileSelect={handleFileSelect} />;
        } else if (zipObj.uploaded || zipObj.uploadStarted) {
            return (
                <ZipUploadPreviewBox
                    zipObj={zipObj}
                    handleFileRemove={handleFileRemove}
                />
            );
        }
        return null;
    };

    return (
        <div >
            <label className='input--label' htmlFor="thumbnail" >
                {zipObj.media ? zipObj.media : <span>Group Zip</span>}
            </label>
            <Field
                name={"character"}
                type="hidden"
                component="input"
                id="thumbnail"
                placeholder="thumbnail"
            />
            <Field
                name="id"
                type="hidden"
                component="input"
                id="id"
                placeholder="id"
            />
            {renderZipUploadContainer()}
        </div>
    )
}

export default ZipUpload
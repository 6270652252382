import DeleteConfirmationModal from "./DeleteConfirmationModal";

export function GroupCardDeleteConfirmation(props: any) {
  const {
    charId,
    setOpenDelete,
    openDelete,
    groupMediaName,
    groupIndex,
    groups,
    setGroupList
  } = props;

  const handleClose = () => {
    setOpenDelete({ state: false, index: 0 });
  };

  let splitted_image_name = "";
  splitted_image_name = groupMediaName;

  const deleteConfirmed = async () => {

    let tempArray = []
    groups.forEach(x => {
      if (x.id === charId) {
        x.groupMediaName = ""
        x.groupMediaPath = ""
      }
      tempArray.push(x)
    })
    setGroupList(tempArray)

    setOpenDelete({ state: false, index: 0 });
  };
  return (
    <DeleteConfirmationModal
      open={openDelete['state']}
      handleClose={handleClose}
      deleteConfirmed={deleteConfirmed}
      modalTitle={"Delete Confirmation"}
      modalContent={<span>
        Are you sure you want to delete
        {""}<strong> {splitted_image_name} </strong> for group <strong> {groupIndex} </strong>?
      </span>}
    />
  )
}
import { Box, Button, Modal } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff';
import CancelIcon from '@mui/icons-material/Cancel';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../routes';
import { SEND_CUSTOM_PUSH } from '../constants';
import { cancelIcon, styleAppVersion } from '../theme/common-styles';
import SubmitSectionButton from './SubmitSectionButton';

const style = {
  ...styleAppVersion,
};


const initialFormData = {
  content: "",
  title: "",
}

export default function PushPopUpModal(props: any) {
  const dispatch = useDispatch();

  let uid = props.uid;

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [initialForm] = useState(initialFormData);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    dispatch(ShowProgressBar());
    let requestData = {
      title: e.title,
      content: e.content,
      project_type: props.project,
      uid: uid
    }

    let response: any = null;
    response = await postRequest(SEND_CUSTOM_PUSH, requestData, accessToken);

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        handleClose()
      }
    }
  }

  return (
    <>
      <Button variant="outlined" size='large' onClick={handleOpen}>Notify Users</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={handleClose}
            sx={cancelIcon} />

          <h3 style={{marginTop:0}}>{"Notify Users"}</h3>
          <Form
            initialValues={{ ...initialForm }}
            onSubmit={handleSubmit}>
            {
              (props: any) => (
                <form onSubmit={props.handleSubmit}>
                  <TextField
                    label="Title (max 48 chars)" name={`title`}
                    type="text"
                    required
                    inputProps={{
                      maxLength: 48
                    }}
                  />
                  <TextField
                    label="Content (max 100 chars)" name={`content`}
                    sx={{
                      mt: "20px"
                    }}
                    type="text"
                    required={true}
                    inputProps={{
                      maxLength: 100
                    }}
                  />
                  <SubmitSectionButton
                    variant="contained"
                    type="submit"
                    label="Submit"
                  />
                </form>
              )
            }
          </Form>
        </Box>
      </Modal>
    </>
  )
}

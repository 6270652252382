import { Box, Button, Modal } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff';
import CancelIcon from '@mui/icons-material/Cancel';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../routes';
import { ADD_CLONE_JIGSAW } from '../constants';
import { ResetUserInfo } from '../store/Projects';
import { cancelIcon, styleAppVersion } from '../theme/common-styles';
import SubmitSectionButton from '../components/SubmitSectionButton';

const style = {
  ...styleAppVersion,
  maxWidth:"unset"
};


const initialFormData = {
  projectName: "",
  uid: "",
}

export default function AddSuperHeroClone(props: any) {
  const dispatch = useDispatch();

  let { projects, setProjects } = props

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [initialForm] = useState(initialFormData);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    dispatch(ShowProgressBar());
    let requestData = {
      project_name: e.projectName,
      uid: e.uid
    }

    let response: any = null;
    response = await postRequest(ADD_CLONE_JIGSAW, requestData, accessToken);

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        dispatch(ResetUserInfo())
        handleClose()

        setProjects([...projects, {
          id: (Number(projects[projects.length - 1].id) + 1).toString(),
          projectName: e.projectName,
          uid: e.uid
        }])

      }
    }
  }

  return (
    <>
      <Button variant='text' sx={{ fontWeight: "600", cursor: "pointer", background:'transparent' }} onClick={handleOpen}>Add Project</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={handleClose}
            sx={cancelIcon} />

          <Form
            initialValues={{ ...initialForm }}
            onSubmit={handleSubmit}>
            {
              (props: any) => (
                <form onSubmit={props.handleSubmit}>
                  <h3>Add Project</h3>
                  <TextField
                    label="Project Name" name={`projectName`}
                    type="text"
                    required
                    inputProps={{
                      maxLength: 32
                    }}
                  />
                  <TextField
                    label="Unique ID" name={`uid`}
                    sx={{
                      mt: "20px"
                    }}
                    type="text"
                    required={true}
                    inputProps={{
                      maxLength: 24
                    }}
                  />
                  <SubmitSectionButton
                    variant="contained"
                    type="submit"
                    label="Submit"
                  />
                </form>
              )
            }
          </Form>
        </Box>
      </Modal>
    </>
  )
}

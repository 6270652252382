import { Button, Stack } from '@mui/material';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

const ForceAppUpdateForm = (props) => {
  const { initialAppStoreForm, handleStoreAppSubmit, formTitle } = props;
  
  return (
    <div className="app-version">
      <div style={{ fontWeight: 700, marginTop: "20px" }}>
       {formTitle}
      </div>

      <div>
        <Form
          initialValues={{ ...initialAppStoreForm }}
          onSubmit={handleStoreAppSubmit}
        >
          {(props: any) => (
            <form onSubmit={props.handleSubmit}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 4 }}
              >
                <input type="hidden" name={`level_id`} />
                <TextField
                  label="Android"
                  name={`android_store_version`}
                  onInput={(e: any) => {
                    const target = e.target;
                    target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  type="number"
                  required={true}
                />
                <TextField
                  label="ios"
                  name={`ios_store_version`}
                  onInput={(e: any) => {
                    const target = e.target;
                    target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  type="number"
                  required={true}
                />
                <div
                  className="button-section"
                >
                  <Button style={{
                    paddingBlock: "16.5px",
                  }}
                    type="submit" variant="contained">
                    Save
                  </Button>
                </div>
              </Stack>
            </form>
          )}
        </Form>
      </div>
    </div>

  )
}

export default ForceAppUpdateForm
import { Box, Button, Modal } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../routes';
import { AppVersionApis } from '../constants';
import { useParams } from 'react-router-dom';
import { cancelIcon, styleAppVersion } from "../theme/common-styles"
import ForceAppUpdateForm from '../components/ForceAppUpdateForm';
import ForAddUpdateForm from '../components/ForAddUpdateForm';


const initialFormData = {
    android_version: "",
    ios_version: "",
    ad_delay_time: ""
}


const initialAppStoreFormData = {
    android_store_version: "",
    ios_store_version: ""
}

export default function AddSuperHeroClone(props: any) {
    const dispatch = useDispatch();
    let { uid, version } = useParams();

    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [initialForm, setInitialForm] = useState(initialFormData);
    const [initialAppStoreForm, setInitialAppStoreForm] = useState(initialAppStoreFormData);


    useEffect(() => {
        getAppVersion()
    }, [open])

    const getAppVersion = async () => {
        let res: any = {}

        res = await postRequest(
            AppVersionApis.SUPERHERO_APP_VERSION_LIST, {
            uid: uid
        }, accessToken,
            (version ?? "")
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let responseData = res.data.data

                setInitialForm({
                    android_version: responseData.android_version,
                    ios_version: responseData.ios_version,
                    ad_delay_time: responseData.ad_delay_time
                })

                setInitialAppStoreForm({
                    android_store_version: responseData.android_store_version,
                    ios_store_version: responseData.ios_store_version,
                })
            }
        }
    }

    const handleSubmit = async (e) => {
        dispatch(ShowProgressBar());
        let data = {
            android_version: e.android_version,
            ios_version: e.ios_version,
        };
        if (version !== "v2") {
            data['ad_delay_time'] = e.ad_delay_time
            data['uid'] = uid
        }

        let response: any = null;

        response = await postRequest(AppVersionApis.SUPERHERO_APP_VERSION_UPDATE, data, accessToken, (version ?? ""));

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                const resData = response.data
                dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                handleClose()
            }
        }
    }

    const handleStoreAppSubmit = async (e) => {
        dispatch(ShowProgressBar());
        let data = {
            android_version: e.android_store_version,
            ios_version: e.ios_store_version,
            title: "New Version avialable",
            description: "New Version avialable",
            uid: uid
        };

        let response: any = null;
        response = await postRequest(AppVersionApis.SUPERHERO_APP_STORE_VERSION_UPDATE, data, accessToken);

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                const resData = response.data
                dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                handleClose()
            }
        }
    }

    return (
        <>
            <Button variant="contained" sx={{
                fontWeight: "600"
            }}
                onClick={handleOpen}>Version</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleAppVersion}>
                    <CancelIcon
                        onClick={handleClose}
                        sx={cancelIcon}
                    />

                    <ForAddUpdateForm
                        initialForm={initialForm}
                        handleSubmit={handleSubmit}
                        version={version}
                        formTitle="For Ads"
                    />

                    <ForceAppUpdateForm
                        initialAppStoreForm={initialAppStoreForm} handleStoreAppSubmit={handleStoreAppSubmit}
                        formTitle="Force update"
                    />
                </Box>
            </Modal>
        </>
    )
}

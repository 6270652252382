import ReactDOM from 'react-dom/client';
import App from './App';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <CacheBuster
    currentVersion={packageJson.version}
    isEnabled={true}
    isVerboseMode={true}
  >
    <App />
  </CacheBuster>
);
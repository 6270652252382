import { useDispatch } from "react-redux";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { isApiCodeSucess, postRequest } from "../data/helpers/services";
import { SuperHeroEndpoints } from "../constants";
import { ShowSuccessAlert } from "../store/alerts";

export function ProductLevelDeleteConfirmation(props: any) {
  const {
    deleteAllLevelConfirmation,
    setDeleteAllLevelConfirmation,
    levelId,
    accessToken,
    version,
    level,
    uid
  } = props;

  const handleClose = () => {
    setDeleteAllLevelConfirmation(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteConfirmed = async () => {
    const res = await postRequest(
      SuperHeroEndpoints.LEVEL_DELETE,
      {
        "level_id": levelId,
        "uid": uid
      },
      accessToken,
      version
    )
    if (res) {
      if (isApiCodeSucess(res)) {
        dispatch(ShowSuccessAlert({
          visible: true,
          message: `${res.data.message}`
        }));
        navigate(0)
      } else {
        setDeleteAllLevelConfirmation(false);
      }
    }

  };
  return (
    <DeleteConfirmationModal
      open={deleteAllLevelConfirmation}
      handleClose={handleClose}
      deleteConfirmed={deleteConfirmed}
      modalTitle={"Delete Confirmation"}
      modalContent={<span>
        Are you sure you want to delete Level-{level} ?
      </span>}
    />
  )
}
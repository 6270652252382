import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { AccessTokenContext } from '../routes';
import { isApiCodeSucess, tlPostRequest } from '../data/helpers/services';
import { TinyLearnerEndpoints } from '../constants';

export function DeleteAllLevelConfirmation(props:any) {
    
    const {
        setOpenAllLevelDelete,
        openAllLevelDelete,
        groupId
    } = props;

    const navigate = useNavigate();

    const getCookieAccessToken:any = useContext(AccessTokenContext);
    let accessToken:string = getCookieAccessToken.cookieAccessToken;

    const handleClose = () => {
        setOpenAllLevelDelete(false);
    };


    const deleteConfirmed = async() => {    
        const res = await tlPostRequest(
            TinyLearnerEndpoints.ALL_LEVEL_ZIP_DELETE,
            {
                "group_id": groupId,
            },
            accessToken
        );
        if (res){
            if(isApiCodeSucess(res)){
                setOpenAllLevelDelete(false);
                navigate(`/tinylearner/group/${groupId}`)
            }
            else{
                setOpenAllLevelDelete(false);
            }
        }
    };

    return (
        <div>
            <Dialog
            open={openAllLevelDelete}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                <h4>{"Delete Confirmation"}</h4>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span>
                        Are you sure you want to delete All zips of this group?
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className='cancel-class' onClick={handleClose}>
                    Cancel
                </button>

                <button className='delete-class' onClick={deleteConfirmed}>
                    Delete
                </button>
                </DialogActions>
            </Dialog>
        
        </div>
    )
}
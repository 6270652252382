import { mainlogoStyle } from "../../theme/common-styles";

const ZipLogo = ({ username, logoText }) => {
    return (
        <div style={mainlogoStyle}>
        </div>
    );
};


export default ZipLogo;


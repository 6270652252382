import { DeleteObjectCommand } from '@aws-sdk/client-s3';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import {s3Client} from "../constants/s3Bucket"


export function LevelPositionDeleteConfirmation(props: any) {
    const { setOpenDelete, openDelete, imageArray, setImageArray, fields } = props;

    const handleClose = () => {
        setOpenDelete({ state: false, index: 0 });
    };

    const removeImages = async (index) => {
        let tempArray = imageArray
        let fgImg = tempArray[index * 2]
        let bgImg = tempArray[index * 2 + 1]
        let images = [fgImg, bgImg]

        images.forEach(async (image) => {
            const input = {
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: image
            }
            const command = new DeleteObjectCommand(input);
            await s3Client.send(command);
        }
        )
        tempArray.splice(index * 2 + 1, 1)
        tempArray.splice(index * 2, 1)

        let newArray = []
        tempArray.forEach((x, i) => {
            if (i >= index * 2) {
                let a = x.Id
                let numb = a.match(/\d/g).join("");
                x.Id = a.replace(`${numb}`, `${numb - 1}`)
            }
            newArray.push(x)
        })

        setImageArray(newArray)
    }

    const deleteConfirmed = async () => {
        fields.remove(openDelete['index'])
        removeImages(openDelete['index'])
        setOpenDelete({ state: false, index: 0 });
    };


    return (
        <DeleteConfirmationModal
            open={openDelete['state']}
            handleClose={handleClose}
            deleteConfirmed={deleteConfirmed}
            modalTitle={"Delete Confirmation"}
            modalContent={"Are you sure you want to delete this index ?"}
        />
    )
}
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));



const styleAppVersion = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  borderRadius: "10px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderTopRightRadius: "24px",
};


const envTextStyle = {
  color: '#2065D1',
  fontWeight: 600,
  position: "absolute",
  width: "119px",
  maxWidth: "unset",
  top: "10px",
  left: "10px",
  p: 4,
  height: "80px"
} as any;


const logoStyle = {
  position: "absolute",
  width: "119px",
  left: "32px",
  maxWidth: "unset",
  top: "10px",
  height: "80px"
} as any;

const cancelIcon = {
  color: "#ccc",
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  width: "1.25em",
  height: "1.25em"
} as any;

const navLinkStyle = {
  textDecoration: "none",
  color: 'inherit',
  alignSelf: "center",
  cursor: "pointer"
}

const logoUrl = '/assets/zip1.svg'

const mainlogoStyle = {
  backgroundImage: `url(${logoUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '55px',
  height: '55px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const listButtonStyle = {
  background: 'transparent',
  minWidth: 'fit-content',
  width: "fit-content",
  height: "fit-content",
}

const cardListStyle = {
  width: "100%",
  background: "#FFFFFF",
  boxShadow: "0px 0px 18px rgba(0, 64, 205, 0.06)",
  borderRadius: "12px",
  padding: 16,
  boxSizing: 'border-box',
} as any;


export {
  StyledRoot, StyledSection, StyledContent, styleAppVersion, envTextStyle, logoStyle, cancelIcon, navLinkStyle, logoUrl, mainlogoStyle, listButtonStyle,
  cardListStyle
}
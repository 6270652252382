import Cookies from "js-cookie";

/**
 * Get the access token from cookies
 * @returns {string|null} The access token or null if not found
 */
export const getAccessToken =()=> Cookies.get('access_token');



/**
 * Get the refresh token from cookies
 * @returns {string|null} The refresh token or null if not found
 */
export const getRefreshToken =()=> Cookies.get('refresh_token');


/**
 * Check if the app is running as a PWA
 * @returns {boolean} True if the app is in standalone mode
 */
export const checkIsPWA = ()=> window.matchMedia('(display-mode: standalone)').matches;


// getChangedValues
export function getChangedValues(arr1, arr2) {
  let changedValues = [];
  // Loop through arr1 and compare each element with the corresponding element in arr2
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
          changedValues.push(arr2[i]); // If elements are not equal, push the value from arr2 into changedValues array
      }
  }
  return changedValues;
}
import { Button, Stack } from "@mui/material";
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

const ForAddUpdateForm = (props) => {
  const { initialForm, handleSubmit, version, formTitle, andriodField="android_version",iosField="ios_version" } = props;

  return (
    <>
    <PageStyles />
    <div className="app-version">
      <div style={{ fontWeight: 700 }}>
        {formTitle}
      </div>

      <div className="app-version-form">
        <Form initialValues={{ ...initialForm }} onSubmit={handleSubmit}>
          {(props: any) => (
            <form onSubmit={props.handleSubmit}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 3 }}
              >
                <input type="hidden" name={`level_id`} />
                <TextField
                  label="Android verion"
                  name={andriodField}
                  type="number"
                  required={true}
                />
                <TextField
                  label="ios version"
                  name={iosField}
                  type="number"
                  required={true}
                />
                {version && <>
                  {version !== "v2" ? (
                    <TextField
                      label="Ad delay time"
                      name={`ad_delay_time`}
                      type="number"
                      required={true}
                    />
                  ) : null}</>
                }
                <div
                  className="button-section"
                >
                  <Button style={{
                    paddingBlock: "16.5px",
                  }} type="submit"
                    variant="contained">
                    Save
                  </Button>
                </div>
              </Stack>
            </form>
          )}
        </Form>
      </div>
    </div>
    </>
  )
}

export default ForAddUpdateForm

export function PageStyles() {
  return (
    <style>
      {`
          .app-version-form {
            border-bottom: 1px dotted #ccc !important;
            padding-bottom: 30px;
          }
         
        `}
    </style>
  )
}
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { TinyLearnerEndpoints } from '../../../constants';
import { tlPatchRequest, isApiCodeSucess, tlPostRequest, } from '../../../data/helpers/services';
import { createGroup } from '../../../data/mappers/TinyLearner/createGroup';
import { AccessTokenContext } from '../../../routes';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../../../store/alerts';
import { useDispatch } from 'react-redux';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { S3_BUCKET, s3Client, BASE_URL_TL } from "../../../constants/s3Bucket";
import { GroupDeleteConfirmation } from '../../../components/GroupDeleteConfirmation';
import { GroupCardDeleteConfirmation } from '../../../components/GroupCardDeleteConfirmation';
import GroupCardList from '../../../components/GroupCardList';

GroupCard.propTypes = {
    product: PropTypes.object,
};


export default function GroupCard({ group, editableIndex, setEditableIndex, groups, setGroupList }) {
    let {
        id,
        groupIndex,
        groupName,
        groupMediaName,
        groupMediaPath,
        isActive,
        levelZipCount,
        zipSize
    } = group;


    if (zipSize) {
        if (Number(zipSize) > 1024) {
            zipSize = `${(zipSize / 1024).toFixed(2)} MB`;
        }
        else {
            zipSize = `${zipSize} KB`
        }
    }
    const dispatch = useDispatch();

    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const [openDelete, setOpenDelete] = useState({ state: false, index: "" })
    const [groupOpenDelete, setGroupOpenDelete] = useState({ state: false, index: "" })
    const [groupNameState, setGroupNameState] = useState(groupName)

    const uploadFile = async (file) => {
        let filePath = file.name;
        let allowedExtensions = /(\.zip)$/i;
        if (!allowedExtensions.exec(filePath)) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Please upload file having extensions .zip only."
                })
            )
            return false
        }


        if (file.name.includes(" ")) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Zip file name contains spaces kindly remove"
                })
            );
            return false
        }

        let folder = `${file.name}`

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: ("tiny_learner/group/" + folder),
            ContentType: 'application/zip',
        }

        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                let uploaded_zip_name = "";
                const lastIndex = folder.lastIndexOf("/");
                const after = folder.slice(lastIndex + 1);
                uploaded_zip_name = after;

                const dataObj = {
                    "group_id": `${id}`,
                    "group_index": `${groupIndex}`,
                    "group_name": `${groupNameState}`,
                }

                let data = createGroup(dataObj, folder, "edit");
                let response: any = null;

                response = await tlPatchRequest(
                    TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                    data,
                    accessToken
                )
                if (response) {
                    dispatch(HideProgressBar());
                    if (isApiCodeSucess(response)) {
                        dispatch(ShowSuccessAlert({
                            visible: true,
                            message: `${uploaded_zip_name} Uploaded successfully.`
                        }));

                    }
                }

                let tempArray = []
                groups.forEach(x => {
                    if (x.id === id) {
                        x.groupName = groupNameState
                        x.groupMediaName = `${folder}`
                        x.groupMediaPath = `${BASE_URL_TL}${folder}`
                    }
                    tempArray.push(x)
                })
                setGroupList(tempArray)

                // }
            }
        }
        catch (err) {
            console.log(err)
        }
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };


    const handleGroupNameChange = (event) => {
        setGroupNameState(event.target.value);
    };

    const resetGroupEditIndex = (group_id) => {
        setEditableIndex("-1");
    };

    const editGroup = (group_id) => {
        setEditableIndex(String(group_id));
    }

    const updateGroup = async (group_id) => {
        if (!groupMediaName && !groupMediaPath) {
            dispatch(ShowErrorAlert({ visible: true, message: `Group Zip is required.` }))
            return false;
        }
        else {
            const event = {
                "group_id": id,
                "group_index": groupIndex,
                "group_name": groupNameState,
                "group_media_name": groupMediaName,
                "group_media_path": groupMediaPath
            }

            let data = createGroup(event, groupMediaName, "edit");
            let response: any = null;
            response = await tlPatchRequest(
                TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                data,
                accessToken
            );
            if (response) {
                dispatch(HideProgressBar());
                if (isApiCodeSucess(response)) {
                    dispatch(ShowSuccessAlert({
                        visible: true,
                        message: `Group updated successfully.`
                    }));
                    setEditableIndex("-1");
                }
            }
        }
    }


    const deletionProps = {
        charId: id,
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        groupMediaName: groupMediaName,
        accessToken: accessToken,
        groupIndex: groupIndex,
        groups: groups,
        setGroupList: setGroupList,
    }


    const groupDeletionProps = {
        charId: id,
        setGroupOpenDelete: setGroupOpenDelete,
        groupOpenDelete: groupOpenDelete,
        accessToken: accessToken,
    }

    let mediaUrlDownload = `${BASE_URL_TL}${groupMediaName}`

    const updateGroupStatus = async (status) => {
        let res: any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_ACTIVE_INACTIVE,
            {
                group_id: id,
                status: (status ? "1" : "2")
            },
            accessToken
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                const resData = res.data
                dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                let tempArray = []
                groups.forEach(x => {
                    if (x.id === id) {
                        x.isActive = status
                    }
                    tempArray.push(x)
                })

                setGroupList(tempArray)
            }
        }
    }

    return (
        <>
            <GroupCardDeleteConfirmation
                {...deletionProps}
            />

            <GroupDeleteConfirmation
                {...groupDeletionProps}
            />
            
            <GroupCardList
            id={id}
            groupIndex={groupIndex}
            groupMediaName={groupMediaName}
            updateGroupStatus={updateGroupStatus}
            groupNameState={groupNameState}
            levelZipCount={levelZipCount}
            handleGroupNameChange={handleGroupNameChange}
            editableIndex={editableIndex}
            setGroupOpenDelete={setGroupOpenDelete}
            group={group}
            isActive={isActive}
            handleFileSelect={handleFileSelect}
            setOpenDelete={setOpenDelete}
            zipSize={zipSize}
            mediaUrlDownload={mediaUrlDownload}
            updateGroup={updateGroup}
            resetGroupEditIndex={resetGroupEditIndex}
            editGroup={editGroup}
            />
        </>
    );
}


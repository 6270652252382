import { createContext, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';


import { checkIsPWA, getAccessToken, getRefreshToken } from './utils/common-function';
import { getRouteConfig } from './routes/routeConfig';



// ----------------------------------------------------------------------
export const AccessTokenContext = createContext({});


export default function Router() {
  const cookieAccessToken = getAccessToken();
  const cookieRefreshToken = getRefreshToken();
  const isPWA = checkIsPWA();

  const contextValue = useMemo(() => ({
    cookieAccessToken,
    cookieRefreshToken
  }), [cookieAccessToken, cookieRefreshToken]);
  
  const routes = useRoutes(getRouteConfig(isPWA));

  return (
    <AccessTokenContext.Provider value={contextValue}>
      {routes}
    </AccessTokenContext.Provider>
  );
}

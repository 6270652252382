import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootStateType } from '../../../store';
import { isApiCodeSucess, postRequest } from '../../../data/helpers/services';
import { PROJECTS_LIST_API } from '../../../constants';
import { useContext, useState } from 'react';
import { AccessTokenContext } from '../../../routes';
import { SaveProjectsInfo, projectInfoInterface } from '../../../store/Projects';
import AddSuperHeroClone from '../../../pages/AddSuperHeroClone';
import { navLinkStyle } from '../../../theme/common-styles';

const oldProjects = [
  // { url: "/dashboard/level/list/v1", label: " SuperHero(Index Based)" },
  // { url: "/dashboard/level/list/v2", label: " SuperHero(Name Based)" },
  { url: "/tinylearner/group", label: " Tiny Learner" }
]

export default function SideBarNav() {
  const dispatch = useDispatch();
  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  let projectsListObject = useSelector((state: RootStateType) => state.projects);
  const [projects, setProjects] = useState(projectsListObject?.projectsList)

  const getProjectsList = async () => {
    let res: any = {}

    res = await postRequest(PROJECTS_LIST_API, {
    }, accessToken, ""
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let responseData = res.data.data
        let responseArray = responseData.project_list

        if (responseArray.length > 0) {
          let tempArray: projectInfoInterface[] = [];
          responseArray.forEach((resObj) => {
            tempArray.push({
              "id": resObj.id,
              "projectName": resObj.project_name,
              "uid": resObj.uid,
            })
          })
          setProjects(tempArray)
          dispatch(SaveProjectsInfo(tempArray));
        }
      }
    }
  }

  if (projects && projects.length === 0) {
    getProjectsList()
  }

  return (
    <Sidebar>
      <Menu renderExpandIcon={({ open }) => <span>{open ? '-' : '+'}</span>}>
        <SubMenu defaultOpen label="Old Projects">
          {oldProjects?.map((project) => <NavLink
            style={({ isActive }) => ({
              ...navLinkStyle,
              fontWeight: isActive ? "bold" : "normal",
              color: isActive ? "#2065D1" : "#212b56",
            })}
            end={false}
            key={project.url}
            to={project.url}>
            <MenuItem>
              {project.label}
            </MenuItem>
          </NavLink>)}
        </SubMenu>
      </Menu>

      <Menu renderExpandIcon={({ open }) => <span>{open ? '-' : '+'}</span>}>
        <SubMenu defaultOpen label="Jigsaw Clones">
          {
            projects?.map(obj =>
              <NavLink
                key={obj.id}
                style={({ isActive }) => ({
                  ...navLinkStyle,
                  fontWeight: isActive ? "bold" : "normal",
                  color: isActive ? "#2065D1" : "#212b56",
                })}
                to={`/dashboard/${obj.uid}/v3/level/`}
                end={false}
              >
                <MenuItem>
                  {obj.projectName}
                </MenuItem>
              </NavLink>
            )
          }
          {
            projects &&
            <MenuItem>
              <AddSuperHeroClone projects={projects} setProjects={setProjects} />
            </MenuItem>
          }
        </SubMenu>
      </Menu>
    </Sidebar>
  )
}

import { useContext } from "react";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { MEDIA_DELETE } from '../constants';
import { postRequest } from '../data/helpers/services';
import { AccessTokenContext } from "../routes";

export function LevelZipUploadDeleteConfirmation(props: any) {

  const {
    setOpenDelete,
    openDelete,
    levelS3MediaName,
    levelId,
    setLevelS3MediaName,
    setLevelMedia

  } = props;

  const getCookieAccessToken: any = useContext(AccessTokenContext);
  let accessToken: string = getCookieAccessToken.cookieAccessToken;

  const handleClose = () => {
    setOpenDelete({ state: false, index: 0 });
  };

  let splitted_image_name = "";
  const lastIndex = levelS3MediaName.lastIndexOf("/");
  const after = levelS3MediaName.slice(lastIndex + 1);
  splitted_image_name = after;

  const deleteConfirmed = async () => {

    const res = await postRequest(
      MEDIA_DELETE,
      {
        "id": levelId,
        "media_type": "TL_GL"   // Tiny learner group
      },
      accessToken
    );
    setLevelS3MediaName("");
    setLevelMedia("");
    setOpenDelete({ state: false, index: 0 });
  };
  return (
    <DeleteConfirmationModal
      open={openDelete['state']}
      handleClose={handleClose}
      deleteConfirmed={deleteConfirmed}
      modalTitle={"Delete Confirmation"}
      modalContent={<span>
        Are you sure you want to delete this zip
        {" "}<strong> {splitted_image_name} </strong> ?
      </span>}
    />
  )
}
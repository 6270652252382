import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'mui-rff';
import { createGroup } from '../../data/mappers/TinyLearner/createGroup';
import { TinyLearnerEndpoints } from '../../constants';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { isApiCodeSucess, tlPatchRequest, tlPostRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import ZipUpload from '../../components/ZipUpload';
import BackButton from '../../components/BackButton';
import { cardListStyle } from '../../theme/common-styles';

export default function GroupForm(props: any) {
    const { modeType } = props;

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const { groupId } = useParams();
    const initialFormData = {
        group_index: "",
        group_name: "",
        group_id: ""
    }
    const [initialForm, setInitialForm] = useState(initialFormData);

    const [zipObj, setZipObj] = useState({
        uploadStarted: false,
        uploaded: false,
        media: "",
        media_name: "",
        group_index: ""
    })


    useEffect(() => {
        if (modeType === 'add') {
            getAllGroupList();
        }
        else if (modeType === 'edit') {
            getGroupDetail();
        }
    }, []);

    const getAllGroupList = async () => {
        let res: any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUPS,
            "",
            accessToken
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data.group_list;
                let new_group_index = ContentData.length ? ((ContentData[ContentData.length - 1]).group_no + 1) : 1;
                setInitialForm({
                    ...initialForm,
                    group_index: `${new_group_index}`
                }
                )
            }
        }
    }

    const getGroupDetail = async () => {
        let res: any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_DETAIL,
            {
                "group_id": groupId
            },
            accessToken
        );


        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data;
                setInitialForm({
                    group_index: `${ContentData.group_no}`,
                    group_name: ContentData.group_name,
                    // group_id: ContentData.id
                    group_id: groupId
                })
                let group_zip_name = "";
                if (ContentData.group_media_name) {
                    const zip_media = ContentData.group_media_name;
                    const lastIndex = zip_media.lastIndexOf("/");
                    group_zip_name = zip_media.slice(lastIndex + 1);
                }
                setZipObj({
                    uploaded: ContentData.group_media_name,
                    uploadStarted: false,
                    media: ContentData.group_media_name,
                    media_name: group_zip_name,
                    group_index: ContentData.group_no
                })
            }
        }
    }


    let handleSubmit = async (event) => {
        dispatch(ShowProgressBar());
        console.log("event", event)
        let data = createGroup(event, zipObj.media, modeType);
        let response: any = null;

        if (modeType === "add") {
            response = await tlPostRequest(
                TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                data,
                accessToken
            );
        }
        else {
            response = await tlPatchRequest(
                TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                data,
                accessToken
            );
        }

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                const resData = response.data
                dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                navigate('/tinylearner/group')
            }
        }
    }


    const zipProps = {
        zipObj: zipObj,
        setZipObj: setZipObj
    }

    return (
        <>
            <BackButton />
            <Form
                initialValues={{ ...initialForm }}
                onSubmit={handleSubmit}>
                {
                    (props: any) => (
                        <form onSubmit={props.handleSubmit}
                            style={{ ...cardListStyle, gap: 24, marginLeft: 50, maxWidth: 800, padding: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                            <h2 style={{margin:0}}>Create Group</h2>
                                <input type="hidden" name={`group_id`} />
                                <TextField
                                    label="Index" name={`group_index`}
                                    sx={{
                                        width: "100%",
                                    }} type="number"
                                    required={true}
                                    disabled />

                                <TextField
                                    label="Name" name={`group_name`}
                                    sx={{
                                        width: "100%",
                                    }} type="text"
                                    required={true} />


                                <ZipUpload
                                    {...zipProps}
                                    groupId={`${groupId}`}
                                />
                            </div>
                            <div className="button-section">
                                <Button size='large' type="submit" variant="contained">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    )
                }
            </Form>
        </>
    )
}




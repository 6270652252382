import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import { ReactSortable } from "react-sortablejs";
import { useEffect, useState } from 'react';
import { getChangedValues } from '../../../utils/common-function';

// ----------------------------------------------------------------------

export default function ProductList(cardProps) {
  const { setUpdateValues, levelList, setLevelList, products, setProducts, selectedLevels, setSelectedLevels, uid } = cardProps;
  const [editableIndex, setEditableIndex] = useState("-1");

  useEffect(() => {
    setLevelList(products)
  }, [products])


  useEffect(() => {
    let updatedValues = getChangedValues(products, levelList)

    if (updatedValues.length > 0) {
      setUpdateValues(updatedValues)
    } else {
      setUpdateValues([])
    }

  }, [levelList])


  return (
    <>
      {
        localStorage.getItem('level_version') === 'v1' ?
            <div style={{
              display: "grid",
              gap: "25px",
              gridTemplateColumns: "repeat(1, 1fr)",
              minWidth: "750px"
            }}>

              {levelList.map((product) => (
                <Grid key={product.id} item xs={12} sm={6} md={3}>
                  <ShopProductCard
                    product={product}
                    products={products}
                    setProducts={setProducts}
                    editableIndex={editableIndex}
                    setEditableIndex={setEditableIndex}
                    selectedLevels={selectedLevels}
                    setSelectedLevels={setSelectedLevels}
                    uid={uid}
                  />
                </Grid>
              ))}
            </div>
          :
          <ReactSortable style={{
            display: "grid",
            gap: "25px",
            gridTemplateColumns: "repeat(1, 1fr)",
            minWidth: "750px"
          }} list={levelList} 
          setList={setLevelList}>

            {levelList.map((product) => (
              <Grid key={product.id} item xs={12} sm={6} md={3}>
                <ShopProductCard
                  product={product}
                  products={products}
                  setProducts={setProducts}
                  editableIndex={editableIndex}
                  setEditableIndex={setEditableIndex}
                  selectedLevels={selectedLevels}
                  setSelectedLevels={setSelectedLevels}
                  uid={uid}
                />
              </Grid>
            ))}
          </ReactSortable>

      }
    </>
  );
}

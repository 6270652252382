import { useContext, useEffect, useState } from 'react'
import { Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { createLevelsJSONData } from '../../data/mappers/TinyLearner/createGroupLevels';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../../store/alerts';
import { isApiCodeSucess, tlPostRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import { TinyLearnerEndpoints } from '../../constants';
import { levelIndexUpdate } from '../../data/mappers/createLevel';
import YarlNavigationPageStyles from '../../theme/YarlNavigationPageStyles';
import { DeleteAllLevelConfirmation } from '../../components/DeleteAllLevelConfirmation';
import GroupDetailList from '../../components/GroupDetailList';
import GroupEditorToolbar from '../../components/GroupEditorToolbar';
import { getChangedValues } from '../../utils/common-function';


export default function GroupDetail(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken
    const { groupId } = useParams();
    const [open, setOpen] = useState({ state: false, media: "" });

    let initialFormValues = {
        "level_id": null,
        "index": 1,
        "level_name": null
    }

    const [downloadJsonFile, setDownloadJsonFile] = useState([])
    const [initialForm, setInitialForm] = useState([])
    const [zipArray, setZipArray] = useState([])
    const [openAllLevelDelete, setOpenAllLevelDelete] = useState(false)
    const [responseFetched, setResponseFetched] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [groupIndex, setGroupIndex] = useState("");
    const [levelList, setLevelList] = useState([]);

    const [draggableGroupList, setDraggableGroupList] = useState([])
    const [updateValues, setUpdateValues] = useState([]);

    useEffect(() => {
        setDraggableGroupList(levelList)
    }, [levelList])


    useEffect(() => {
        let updatedValues = getChangedValues(levelList, draggableGroupList)
        if (updatedValues.length > 0) {
            setUpdateValues(updatedValues)
        } else {
            setUpdateValues([])
        }
    }, [draggableGroupList])


    useEffect(() => {
        getGroupLevels()
    }, [])

    const getJsonDownload = () => {
        const jsonData = downloadJsonFile;
        // Format the JSON with indentation
        const formattedJson = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([formattedJson], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `group${groupIndex}.json`;
        link.click();
    };

    const getGroupLevels = async () => {
        setDownloadJsonFile([])
        let res: any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_DETAIL,
            {
                group_id: groupId
            },
            accessToken
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let responseData = res.data.data;
                let levelsArray = responseData.levels_list_data;

                if (levelsArray.length > 0) {
                    levelsArray.forEach(level => {
                        let arrayObj = {
                            index: level.level_no,
                            color1: level.color1 ?? "",
                            color2: level.color2 ?? "",
                            color3: level.color3 ?? "",
                            coins: level.coins ?? "",
                            name: level.name ?? "",
                            folder_level: level.level_name ?? "",
                            zipSize: level.zipSize ?? "",
                        }
                        let tempArray = downloadJsonFile
                        tempArray.push(arrayObj)
                        setDownloadJsonFile(tempArray);
                    })
                }

                setLevelList(levelsArray);
                const group_no = responseData.group_no;
                setGroupIndex(group_no)
                const group_name = responseData.group_name;
                setGroupName(group_name)
                if (levelsArray.length > 0) {
                    levelsArray.forEach((resObj, index) => {
                        let tempArray = initialForm;
                        tempArray.push({
                            "level_id": resObj.id,
                            "index": resObj.level_no,
                            "level_name": resObj.level_name
                        })

                        let tempZipArray: any = zipArray;


                        tempZipArray.push({
                            Id: `groupLevels[${index}].level_zip_name`,
                            uploadStarted: false,
                            uploaded: resObj.level_media_name,
                            media: `${resObj.level_media_name}`,
                            level_no: String(`${resObj.level_no}`),
                            level_id: resObj.id,
                            is_deleted: false,
                            zipSize: resObj.zip_size
                        });

                        setZipArray(tempZipArray)
                        setInitialForm(tempArray)
                    })
                }
                else {
                    setInitialForm([{ ...initialFormValues }])
                    let tempZipArray = []
                    tempZipArray.push({
                        Id: `groupLevels[0].level_zip_name`,
                        uploadStarted: false,
                        uploaded: false,
                        media: "",
                        level_id: "",
                        is_deleted: false,
                        zipSize: ""
                    });
                    setZipArray(tempZipArray)
                }
            }
            setResponseFetched(true)
        }
    }


    const updateIndex = async () => {
        let indexSendForUpdate = []
        updateValues.forEach(x => {
            indexSendForUpdate.push(
                {
                    id: x.id,
                    index: draggableGroupList.findIndex(i => i.id === x.id) + 1
                }
            )
        }
        )

        let requestData = levelIndexUpdate(indexSendForUpdate)

        let response: any = null;
        response = await tlPostRequest(TinyLearnerEndpoints.LEVEL_INDEX_UPDATE, requestData, accessToken);

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                const resData = response.data
                dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                navigate(0)
            }
        }
    }

    const validateFileUploaded = (jsonData) => {
        let validated = true;
        if (!Array.isArray(jsonData)) {
            dispatch(ShowErrorAlert({ visible: true, message: `Invalid Json provided` }))
            validated = false;
        }
        jsonData.forEach((x, i) => {
            if (!x.index) {
                dispatch(ShowErrorAlert({ visible: true, message: `Index is required for ${i} position object` }));
                validated = false;
            }
            if (!x.color1) {
                dispatch(ShowErrorAlert({ visible: true, message: `color1 is required for ${i} position object` }));
                validated = false;
            }

            if (!x.color2) {
                dispatch(ShowErrorAlert({ visible: true, message: `color2 is required for ${i} position object` }));
                validated = false;
            }

            if (!x.color3) {
                dispatch(ShowErrorAlert({ visible: true, message: `color3 is required for ${i} position object` }));
                validated = false;
            }

            if (!x.name) {
                dispatch(ShowErrorAlert({ visible: true, message: `name is required for ${i} position object` }));
                validated = false;
            }

            if (!x.folder_level) {
                dispatch(ShowErrorAlert({ visible: true, message: `folder_level is required for ${i} position object` }));
                validated = false;
            }
        })
        return validated
    }

    const handleFileInputChange = async (event) => {
        const fileReader = new FileReader();
        const file = event.target.files[0];

        fileReader.onload = async (event) => {
            const fileContents = event.target.result;
            let jsonData;

            if (typeof fileContents === 'string') {
                try {
                    jsonData = JSON.parse(fileContents);
                }
                catch {
                    dispatch(ShowErrorAlert({ visible: true, message: `Invalid Json provided` }))
                    const navigate_path = '/tinylearner/group/' + groupId + "/";
                    navigate(navigate_path)
                }
            } else {
                // Handle other cases, such as ArrayBuffer
                console.error('Unsupported file type');
            }

            let validated = validateFileUploaded(jsonData);
            if (validated) {
                let data = createLevelsJSONData(jsonData, groupId);
                let response: any = null;
                response = await tlPostRequest(
                    TinyLearnerEndpoints.GROUP_ADD_UPDATE_LEVEL_JSON,
                    data,
                    accessToken
                );
                if (response) {
                    dispatch(HideProgressBar());
                    if (isApiCodeSucess(response)) {
                        const resData = response.data;
                        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                        const navigate_path = '/tinylearner/group/' + groupId + "/";
                        navigate(navigate_path)
                    }
                }
            }
            else {
                const navigate_path = '/tinylearner/group/' + groupId + "/";
                navigate(navigate_path)
            }
        };
        fileReader.readAsText(file);
    };

    const deleteAllLevelZipProps = {
        setOpenAllLevelDelete: setOpenAllLevelDelete,
        openAllLevelDelete: openAllLevelDelete,
        groupId: groupId
    }

    return (
        <Container sx={{ mt: "-70px" }}>
            <DeleteAllLevelConfirmation {...deleteAllLevelZipProps} />
            <YarlNavigationPageStyles />
            <Lightbox
                open={open['state']}
                close={() => setOpen({ state: false, media: "" })}
                slides={
                    [{
                        src: open['media'],
                        alt: "image 1",
                        width: 3840,
                        height: 2560,
                    }]}
             />

            <GroupEditorToolbar
                responseFetched={responseFetched}
                groupName={groupName}
                updateValues={updateValues}
                updateIndex={updateIndex}
                handleFileInputChange={handleFileInputChange}
                getJsonDownload={getJsonDownload}
            />
            <GroupDetailList
                draggableGroupList={draggableGroupList}
                setDraggableGroupList={setDraggableGroupList}
                groupId={groupId}
                groupIndex={groupIndex}
            />
        </Container>
    )
}
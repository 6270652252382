import { Container, Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useResponsive from '../../../hooks/useResponsive';
import {StyledRoot, StyledSection, StyledContent, envTextStyle, logoStyle} from "../../../theme/common-styles"
import ResetPasswordFormSide from './ResetPasswordFormSide';

export default function ResetPasswordForm() {
  const mdUp = useResponsive('up', 'md', '');

  return (
    <>
      <Helmet>
        <title> Reset Password </title>
      </Helmet>

      <StyledRoot>
        <Box>
          <img src="/assets/logoSkycap.svg"
            style={logoStyle} 
            alt=""/>
          <span style={envTextStyle}>
            {process.env.REACT_APP_ENV}
          </span>
        </Box>

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Reset password
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <ResetPasswordFormSide />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

import { Box, Modal } from '@mui/material'
import { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff';
import CancelIcon from '@mui/icons-material/Cancel';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../routes';
import { CHANGE_PASSWORD } from '../constants';
import { cancelIcon, styleAppVersion } from "../theme/common-styles"
import SubmitSectionButton from './SubmitSectionButton';
import { usePasswordValidation } from '../hooks/usePasswordValidation';

const style = {
  ...styleAppVersion,
  minHeight: 300,
};


const initialFormData = {
  password: "",
  confirmPassword: "",
}

export default function PasswordModal(props: any) {
  const dispatch = useDispatch();
  let { openPasswordModal, setOpenPasswordModal } = props;
  const { confirmPasswordError, handlePasswordChange, handleConfirmPasswordChange } = usePasswordValidation();

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [initialForm] = useState(initialFormData);
  const handleClose = () => setOpenPasswordModal(false);

  const handleSubmit = async (e) => {
    if (confirmPasswordError !== "") {
      dispatch(ShowErrorAlert({ visible: true, message: "Password does not match" }));
      return false;
    }

    dispatch(ShowProgressBar());
    let requestData = {
      password: e.password,
    }

    let response: any = null;
    response = await postRequest(CHANGE_PASSWORD, requestData, accessToken, "");

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        handleClose()
      }
    }
  }

  return (
    <Modal
      open={openPasswordModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CancelIcon
          onClick={handleClose}
          sx={cancelIcon} />

        <h3 style={{marginTop:0}}>Edit Password</h3>
        <Form
          initialValues={{ ...initialForm }}
          onSubmit={handleSubmit}>
          {
            (props: any) => (
              <form onSubmit={props.handleSubmit}>
                <TextField
                  label="Password (max 20 chars)" name={`password`}
                  type="text"
                  required
                  inputProps={{
                    maxLength: 20,
                    onChange: handlePasswordChange
                  }}

                />
                <TextField
                  label="Confirm Password" name={`confirmPassword`}
                  sx={{
                    mt: "20px"
                  }}
                  type="text"
                  required={true}
                  inputProps={{
                    maxLength: 20,
                    onChange: handleConfirmPasswordChange

                  }}
                  error={confirmPasswordError !== ""}
                  helperText={confirmPasswordError}

                />
                <SubmitSectionButton
                  variant="contained"
                  type="submit"
                  label="Submit"
                />
              </form>
            )
          }
        </Form>
      </Box>
    </Modal>
  )
}

import { useState } from 'react'
import { Box, Button } from '@mui/material';
import { Field } from 'react-final-form'
import UploadIcon from '../icons/UploadIcon';
import { store } from '../store';
import { ShowErrorAlert } from '../store/alerts';
import {s3Client, BASE_URL_CLONE, S3_BUCKET, BASE_URL} from "../constants/s3Bucket";
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { ImageUploadDeleteConfirmation } from './ImageUploadDeleteConfirmation';
import CancelIcon from '../icons/CancelIcon';

export default function ImageUpload(props:any) {
    const {imageArray, setImageArray, levelNo, setOpen, charId, accessToken, ediatble, uid } = props;

    const [openDelete, setOpenDelete] = useState<{
        state:boolean,
        index:number
    }>({state:false, index:0})

    const { dispatch } = store;

    const [imageObj,setImageObj] = useState(
        props.imageArray.filter(obj =>{ 
            if (obj.Id === props.name) {
                return {...obj}
            }})[0]
    )

    const uploadFile = async (file) => {
        if (file.name.includes(" ")){
            dispatch(ShowErrorAlert({visible:true, message: "Image file name contains spaces kindly remove"}));
            return false
        }
        let folder = `World${levelNo}/${file.name}`
        let key = "superhero/" + folder
        if (uid){
            key = `V3_Clone/${uid}/${folder}`
        }

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: key,
            ContentType: 'image/jpeg',
        }

        const command = new PutObjectCommand(input);

        try{
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200){

                let updatedImageArray = imageArray.map(obj => {
                            if (obj.Id === props.name) {
                                obj.uploadStarted = true 
                                return {...obj}
                            };
                            return obj;
                        })
                    updatedImageArray = imageArray.map(obj => {
                        if (obj.Id === props.name) {
                            obj.uploadStarted = false 
                            obj.uploaded = true
                            obj.media = `${folder}`
                            return {...obj}
                        };
                        setImageObj({...imageObj, media:folder, uploaded:true, uploadStarted:false })
                        return obj;
                    })
                setImageArray(updatedImageArray)
            }
        }
        catch(err){
            console.log(err)
        }
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };


    const deletionProps = {
        charId: charId,
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        levelNo: levelNo,
        dispatch: dispatch,
        imageObj: imageObj,
        imageArray: imageArray,
        setImageArray: setImageArray,
        setImageObj: setImageObj,
        imageType: props.imageType,
        accessToken: accessToken,
        uid: uid
    }

    return (
         <div className="col-12 md-col-4" 
            style={{
                width:"100%",
                // @ts-ignore
                textAlign: "-webkit-center",
            }}
            >
            <label className='input--label' htmlFor="thumbnail">
                {props.imageType} image
            </label>
            <Field
                name={props.name}
                type="hidden"
                component="input"
                id="thumbnail"
                placeholder="thumbnail"
                disabled= {ediatble}
            />
            <Field
                name="id"
                type="hidden"
                component="input"
                id="id"
                placeholder="id"
                disabled= {ediatble}
            />
    
            {(!imageObj.uploaded)
                ?
                (
                    <Box component="div"
                        sx={{
                            width: '20%',
                            background: '#232157',
                            marginBottom: "20px",
                            position: 'relative',
                            borderRadius: "15px",
                            cursor: "pointer",
                            aspectRatio: '4/3'
                        }}
                    >
                        <Box
                            component="div"
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <UploadIcon />
                        </Box>
                        
                        <Box
                            component="input"
                            disabled= {ediatble}
                            type="file"
                            name="thumbnail"
                            onChange={handleFileSelect}
                            sx={{
                                height: '100%',
                                width: '100%',
                                opacity: '0',
                                cursor: 'pointer',
                                left: '50%'
                            }}
                        ></Box>
                    </Box>
                )
                :
                (imageObj.uploaded || imageObj.uploadStarted) ? (
                    <Box className="img--uploadBg">
                    
                        {
                            (!imageObj.uploaded)
                            ?
                            ''
                            : 
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    marginTop: "12px"
                                }}

                            >
                                <img
                                    src={
                                    (uid)?
                                    `${BASE_URL_CLONE}${uid}/${imageObj.media}`
                                        :
                                        `${BASE_URL}${imageObj.media}`
                                    }
                                    style={{
                                        width: '65px',
                                        height: '65px',
                                        borderRadius: '50%'
                                    }}
                                onClick={() =>setOpen({state:true, 
                                    
                                    media:
                                    (uid) ? 
                                    `${BASE_URL_CLONE}${uid}/${imageObj.media}`
                                    :    
                                    `${BASE_URL}${imageObj.media}`
                                
                                })}
                                alt=''
                                />

                                <ImageUploadDeleteConfirmation
                                    {...deletionProps}
                                />
                                
                                {(ediatble) ?
                                    <Button style={{ background: "transparent",position:'absolute',top:0, right:0,zIndex:9, cursor:'pointer' }}
                                    onClick={() => {
                                        setOpenDelete({
                                            state: true,
                                            index: levelNo
                                        })
                                    }}>
                                    <CancelIcon />
                                </Button>
                                : ""
                            }
                            </div>
                            }
                    </Box>
                ) : ""
                }
             </div>
      )
}



import { Stack } from '@mui/material';
import { TextField } from 'mui-rff';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageUpload from '../components/imageUpload';

const LevelPositionList = (props) => {
  const { name, editable, imageProps, fields, index, uid, setOpenDelete } = props
  return (
    <div key={name}>
      <Stack mb={5}
        sx={{
          background: "#e6e8ef91",
          borderRadius: "16px",
          padding: "16px 24px"
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}
          alignItems="center"
        >
            <input type="hidden" name={`${name}.char_id`} />
            <input type="hidden" name={`${name}.index`} />

          <TextField
            label="X Move" name={`${name}.x_move`}
            sx={{
              width: "12%",
              background: "#e6e7ec"
            }} type="number"
            required={true}
            disabled={!editable}
          />

          <TextField
            label="Y Move" name={`${name}.y_move`}
            sx={{
              width: "12%",
              background: "#e6e7ec"
            }} type="number"
            required={true}
            disabled={!editable}
          />

          <TextField
            label="Real X Position"
            name={`${name}.real_x_position`}
            sx={{
              width: "12%",
              background: "#e6e7ec"
            }} type="number"
            required={true}
            disabled={!editable}
          />

          <TextField
            label="Real Y Position"
            name={`${name}.real_y_position`}
            sx={{
              width: "12%",
              background: "#e6e7ec"
            }}
            type="number"
            required={true}
            disabled={!editable}
          />

          <div style={{ width: "12%" }}>
            <ImageUpload
              {...imageProps}
              imageType={"FG"}
              name={`${name}.foreground`}
              charId={fields.value[index].char_id}
              uid={uid}
            />
          </div>

          <div
            style={{
              width: "15%",
            }}
          >
            <ImageUpload
              {...imageProps}
              imageType={"BG"}
              name={`${name}.background`}
              charId={fields.value[index].char_id}
              uid={uid}
            />
          </div>

          {
            editable ?
              <div
                style={{
                  width: "15%"
                }}
              >
                {(fields.length > 1) ?
                  <button
                    tabIndex={-1}
                    style={{
                      background: "none",
                      border: "none",
                      marginLeft: "10%"
                    }} type="button"
                    onClick={() => {
                      setOpenDelete({
                        state: true,
                        index: index
                      })
                    }}>
                    <DeleteIcon sx={{ color: "red" }} fontSize={'large'} />
                  </button>
                  : ""
                }
              </div>
              : ""}
        </Stack>
      </Stack>
    </div>
  )
}

export default LevelPositionList
import RenderImageDisplay from "./RenderImageDisplay";
import RenderUploadBox from "./RenderUploadBox";

const RenderImageContent = (props) => {
    const { imageObj, handleFileSelect, uid, handleFileRemove } = props;

    const renderContent = () => {
        if (!imageObj.uploaded) {
            return <RenderUploadBox handleFileSelect={handleFileSelect}/>;
        } else if (imageObj.uploaded || imageObj.uploadStarted) {
            return <RenderImageDisplay imageObj={imageObj} handleFileRemove={handleFileRemove} uid={uid}/>;
        } else {
            return "";
        }
    }
    return (
        <>
             {renderContent()}
        </>
    )
}

export default RenderImageContent
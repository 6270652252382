import { Navigate } from "react-router-dom";
import { getAccessToken } from "../../utils/common-function";

const IsLoggedIn = ({ children, ...rest }: any) => {
  let accessToken = getAccessToken()

  if (
    accessToken
    &&
    typeof accessToken !== "undefined"
    &&
    accessToken !== "null"
    &&
    accessToken?.length > 0
  ) {
    window.history.replaceState(null, '', '/dashboard/super_hero/v3/level/');
    return <Navigate to={{ pathname: "/dashboard/super_hero/v3/level/" }} />
  }
  return children;
}

export default IsLoggedIn
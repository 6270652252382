import { Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const RenderImageDisplay = (props) => {
  const { imageObj, uid, handleFileRemove } = props;

  return (
    <Box className="img--uploadBg" style={{
      minHeight: '100px',
      maxHeight: '100px'
    }}>
      {(!imageObj.uploaded) ?
        '' :
        <button type='button' className="remove--pic" onClick={handleFileRemove}>
          <CancelIcon />
        </button>
      }
      <Box
        component="img"
        src={

          uid ?

            `${process.env.REACT_APP_S3_BASE_PATH_CLONE}${uid}/${imageObj.media}`

            :
            `${process.env.REACT_APP_S3_BASE_PATH}${imageObj.media}`
        }
        sx={{
          maxHeight: '20%',
          maxWidth: '40%',
          objectFit: 'contain',
          borderRadius: '15px',
          margin: 'auto'
        }}
      ></Box>
    </Box>
  )
}

export default RenderImageDisplay
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const DeleteConfirmationModal = (props) => {
  const { open, handleClose, deleteConfirmed, modalTitle, modalContent } = props;
  return (
    <div>
      <Dialog
        open={open}
        maxWidth={'xl'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 style={{ margin: 0 }}>{modalTitle}</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="primary" className='cancel-class' onClick={handleClose}>
            Cancel
          </Button>

          <Button className='delete-class' color="error" onClick={deleteConfirmed}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default DeleteConfirmationModal


import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import ResetPasswordForm from '../sections/auth/login/ResetPasswordForm';
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
import ProductsPage from '../pages/ProductsPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import LevelPositions from '../pages/LevelPositions';
import LevelForm from '../pages/LevelForm';
import GroupsPage from '../pages/tinyLearner/GroupsPage';
import GroupForm from '../pages/tinyLearner/GroupForm';
import GroupDetail from '../pages/tinyLearner/GroupDetail';
import BaseAppVersion from '../pages/AppVersion/Base';
import PrivateRoute from '../components/routes/PrivateRoute';
import IsLoggedIn from '../components/routes/IsLoggedIn';
import { ResetPasswordFormLazy, DashboardLayoutLazy, SimpleLayoutLazy, ProductsPageLazy, LoginPageLazy, Page404Lazy, LevelPositionsLazy, LevelFormLazy, GroupsPageLazy, GroupFormLazy, GroupDetailLazy, BaseAppVersionLazy } from "./lazyImports"

export const getRouteConfig = (isPWA:boolean) =>    [
  {
    path: '/dashboard',
    element:
      <PrivateRoute>
        {isPWA ?
          <DashboardLayout />
          :
          <Suspense fallback={<div>Loading...</div>}>
            <DashboardLayoutLazy />
          </Suspense>
        }
      </PrivateRoute>,
    children: [
      { element: <Navigate to="/dashboard/level/list/:version" />, index: true },
      {
        path: 'level/list/:version', element:
          <PrivateRoute>
            {isPWA ?
              <ProductsPage />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <ProductsPageLazy />
              </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: 'level/create', element:
          <PrivateRoute>
            {isPWA ?
              <LevelForm modeType={'add'} />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <LevelFormLazy modeType={'add'} />
              </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: 'level/edit/:levelId', element:
          <PrivateRoute>
            {
              isPWA ?
                <LevelForm modeType={'edit'} />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <LevelFormLazy modeType={'edit'} />
                </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: 'level/:levelId', element:
          <PrivateRoute>
            {
              isPWA ?
                <LevelPositions />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <LevelPositionsLazy />
                </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: ':uid/:version/level/', element:
          <PrivateRoute>
            {
              isPWA ?
                <ProductsPage />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <ProductsPageLazy />
                </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: ':uid/level/create', element:
          <PrivateRoute>
            {
              isPWA ?
                <LevelForm modeType={'add'} />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <LevelFormLazy modeType={'add'} />
                </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: ':uid/level/edit/:levelId', element:
          <PrivateRoute>
            {
              isPWA ?
                <LevelForm modeType={'edit'} />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <LevelFormLazy modeType={'edit'} />
                </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: ':uid/level/:levelId', element:
          <PrivateRoute>
            {
              isPWA ?
                <LevelPositions />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <LevelPositionsLazy />
                </Suspense>
            }
          </PrivateRoute>
      },
    ],
  },
  {
    path: '/tinylearner',
    element:
      <PrivateRoute>
        {
          isPWA ?
            <DashboardLayout />
            :
            <Suspense fallback={<div>Loading...</div>}>
              <DashboardLayoutLazy />
            </Suspense>
        }
      </PrivateRoute>,
    children: [
      { element: <Navigate to="/tinylearner/group" />, index: true },
      {
        path: 'group', element:
          <PrivateRoute>
            {isPWA ?
              <GroupsPage />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <GroupsPageLazy />
              </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: 'group/create', element:
          <PrivateRoute>
            {
              isPWA ?
                <GroupForm modeType={'add'} />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <GroupFormLazy modeType={'add'} />
                </Suspense>
            }
          </PrivateRoute>
      },
      {
        path: 'group/edit/:groupId', element:
          <Suspense fallback={<div>Loading...</div>}>
            <PrivateRoute>
              <GroupForm modeType={'edit'} />
            </PrivateRoute>
          </Suspense>
      },
      {
        path: 'group/:groupId', element:
          <PrivateRoute>
            {
              isPWA ?
                <GroupDetail />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <GroupDetailLazy />
                </Suspense>
            }
          </PrivateRoute>
      },
    ],
  },
  {
    path: '/appVersion',
    element:
      <PrivateRoute>
        {
          isPWA ?
            <DashboardLayout />
            :
            <Suspense fallback={<div>Loading...</div>}>
              <DashboardLayoutLazy />
            </Suspense>
        }
      </PrivateRoute>,
    children: [
      {
        path: '', element:
          <PrivateRoute>
            {isPWA ?
              <BaseAppVersion />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <BaseAppVersionLazy />
              </Suspense>
            }
          </PrivateRoute>
      },
    ],
  },
  {
    path: '/login',
    element:
      <IsLoggedIn>
        {
          isPWA ?
            <LoginPage />
            :
            <Suspense fallback={<div>Loading...</div>}>
              <LoginPageLazy />
            </Suspense>
        }
      </IsLoggedIn>
  },
  {
    path: '/account/activate/:uuid',
    element:
      <>
        {
          isPWA ?
            <ResetPasswordForm />
            :
            <Suspense fallback={< div > Loading...</div>}>
              <ResetPasswordFormLazy />
            </Suspense >
        }
      </>
  },
  {
    element:
      <>
        {
          isPWA ?
            <SimpleLayout />
            :
            <Suspense fallback={<div>Loading...</div>}>
              <SimpleLayoutLazy />
            </Suspense>
        }
      </>,
    children: [
      { element: <Navigate to="/dashboard/super_hero/v3/level/" replace />, index: true },
      {
        path: '404', element:
          <>
            {
              isPWA ?
                <Page404 />

                :
                <Suspense fallback={<div>Loading...</div>}>
                  <Page404Lazy />
                </Suspense>
            }
          </>
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
];
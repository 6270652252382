import { useContext, useEffect, useState } from 'react'
import { AppVersionApis } from '../../constants';
import { isApiCodeSucess, tlPostRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import ForAddUpdateForm from '../../components/ForAddUpdateForm';

const initialFormData = {
  tl_android_version: "1",
  tl_ios_version: "1"
}

export default function TlAppVersion() {
  const dispatch = useDispatch();

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [initialForm, setInitialForm] = useState(initialFormData);


  useEffect(() => {
    getAppVersion()
  }, [])

  const getAppVersion = async () => {
    let res: any = {}

    res = await tlPostRequest(
      AppVersionApis.TL_APP_VERSION_LIST, "", accessToken);
    if (res) {
      if (isApiCodeSucess(res)) {
        let responseData = res.data.data
        setInitialForm({
          tl_android_version: responseData.android_version,
          tl_ios_version: responseData.ios_version
        })
      }
    }
  }

  const handleSubmit = async (e) => {
    dispatch(ShowProgressBar());
    let data = {
      android_version: e.tl_android_version,
      ios_version: e.tl_ios_version
    };

    let response: any = null;

    response = await tlPostRequest(AppVersionApis.TL_APP_VERSION_UPDATE, data, accessToken);

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
      }
    }
  }

  return (
      <ForAddUpdateForm
        initialForm={initialForm}
        handleSubmit={handleSubmit}
        formTitle="Tiny Learner App Versions"
        andriodField="tl_android_version"
        iosField="tl_android_version"
      />
  )
}
import PropTypes from 'prop-types';
import { TextField as MUITextField, Card, Button, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useContext, useState } from 'react';
import { isApiCodeSucess, postRequest } from '../../../data/helpers/services';
import { LEVEL_CREATE_API, SuperHeroEndpoints } from '../../../constants';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../../../routes';
import SuperHeroLevelImageUploadOnS3 from '../../../components/SuperHeroLevelImageUpload';
import { createLevel } from '../../../data/mappers/createLevel';
import CustomToggle from '../../../components/CustomToggle';
import DeleteIcon from '../../../static/DeleteIcon';
import { ProductLevelDeleteConfirmation } from '../../../components/ProductLevelDeleteConfirmation';
import { cardListStyle, listButtonStyle } from '../../../theme/common-styles';


ShopProductCard.propTypes = {
  product: PropTypes.object,
};


export default function ShopProductCard({ product, products, setProducts, editableIndex, setEditableIndex, selectedLevels,
  setSelectedLevels, uid }) {
  const {
    characterImage,
    characterImageName,
    id,
    level,
    newLevel,
    scaling,
    isActive,
    imageSize
  } = product;

  const editLevel = (level_id) => {
    setEditableIndex(String(level_id));
  }

  let versionLocalStorage = localStorage.getItem('level_version')
  const updateLevel = async (level_id) => {
    if (!characterImageName && !characterImage) {
      dispatch(ShowErrorAlert({ visible: true, message: `Character image is required.` }))
      return false;
    }
    else {
      let response: any = null;
      const event = {
        "level_id": `${level_id}`,
        "media_path_name": `${characterImageName}`,
        "media_path": `${characterImage}`,
        "level": level,
        "scale": scale
      }
      let data = createLevel(
        event,
        characterImageName,
        "edit",
        newLevel,
        uid
      );



      response = await postRequest(
        LEVEL_CREATE_API,
        data,
        accessToken,
        (version ?? "")
      );
      dispatch(ShowProgressBar())

      if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
          dispatch(ShowSuccessAlert({
            visible: true,
            message: `Level updated successfully.`
          }));
          setEditableIndex("-1");
        }
      }
    }
  }

  const [deleteAllLevelConfirmation, setDeleteAllLevelConfirmation] = useState(false)
  const [scale, setScale] = useState(scaling);
  const handleScaleChange = (event) => {
    setScale(event.target.value);
  };

  const [characterImageState, setCharacterImageState] = useState(characterImage);
  const [characterImageNameState, setCharacterImageNameState] = useState(characterImageName);

  const dispatch = useDispatch();
  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  let version = versionLocalStorage

  const onLevelToggleHandle = async () => {
    dispatch(ShowProgressBar())
    let res: any = {}

    res = await postRequest(
      SuperHeroEndpoints.ACTIVE_INACTIVE_LEVEL,
      {
        level_id: id,
        status: (isActive ? "2" : "1"),
        uid: uid
      },
      accessToken,
      (version ?? "")

    );
    if (res) {
      if (isApiCodeSucess(res)) {
        const resData = res.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        let tempArray = []
        products.forEach(x => {
          if (x.id === id) {
            x.isActive = !isActive
          }
          tempArray.push(x)
        })
        setProducts(tempArray)
        dispatch(HideProgressBar())

      }
    }
  }

  const customLevelToggleProps = {
    onToggleHandle: onLevelToggleHandle,
    checked: isActive
  }

  const [isChecked, setIsChecked] = useState<boolean>(
    () => {
      if (selectedLevels.includes(id)) {
        return true;
      }
      else {
        return false;
      }
    }

  )

  const levelDeleteConfirmProps = {
    deleteAllLevelConfirmation: deleteAllLevelConfirmation,
    setDeleteAllLevelConfirmation: setDeleteAllLevelConfirmation,
    levelId: id,
    accessToken: accessToken,
    version: version,
    level: level,
    uid: uid
  }


  let onChange = async () => {
    let response: any = null;
    response = await postRequest(SuperHeroEndpoints.Update_Level_Selection,
      {
        selected_level: id.toString(),
        uid: uid
      }
      , accessToken,
      (version ?? "")
    );
    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        setIsChecked(!isChecked)
      }
    }
  }



  return (
    <Card style={cardListStyle}>
      <ProductLevelDeleteConfirmation {...levelDeleteConfirmProps} />

      <Stack direction='row' gap={1} justifyContent="space-between" alignItems={'center'} sx={{ maxHeight: "100%" }}>
        <div>
          {
            versionLocalStorage !== 'v1' &&
            <div style={{width:50}}>
              <Checkbox
                checked={isChecked}
                onChange={() => { onChange() }}
              />
            </div>
          }
        </div>

        <div>
          <MUITextField
            id="outlined-basic"
            label="Scale"
            variant="outlined"
            value={scale}
            onChange={handleScaleChange}
            type="text"
            style={{width:250}}
            disabled={editableIndex !== id}
          />
        </div>
        <div>
          <SuperHeroLevelImageUploadOnS3
            levelId={id}
            character_image={characterImageState}
            set_character_image={setCharacterImageState}
            character_image_name={characterImageNameState}
            set_character_image_name={setCharacterImageNameState}
            levelNo={level}
            scale={scale}
            isNewLevel={newLevel}
            editableIndex={editableIndex}
            products={products}
            setProducts={setProducts}
            uid={uid}
          />
        </div>

        <div>
          {imageSize ? `${imageSize} Kb` : ""}
        </div>


        <div>
          {
            versionLocalStorage === 'v1'
              ? ""
              :
              <CustomToggle {...customLevelToggleProps} />
          }
        </div>

        <Stack gap={0.5} alignItems={'center'} direction={'row'}>
          <div>
            <Link to={
              uid ?
                `/dashboard/${uid}/level/${product.id}`
                :
                "/dashboard/level/" + product.id

            }>
              <Button sx={{ padding: 0 }} style={{...listButtonStyle,color:"#C7CBD6"}}>
                <ArrowCircleRightOutlinedIcon fontSize='large' />
              </Button>
            </Link>
          </div>
          <div>
            {
              (versionLocalStorage !== 'v1') &&
              <div>
                {
                  (editableIndex === id)
                    ?
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "red",
                          "&:hover": {
                            backgroundColor: "red",
                            cursor: "pointer",
                          }
                        }}
                        onClick={() => {
                          setEditableIndex("");
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => {
                          updateLevel(id);
                        }}
                      >
                        Save
                      </Button>
                    </Stack>
                    :
                    <Button style={{ ...listButtonStyle, color: "#C7CBD6" }} onClick={() => editLevel(id)}>
                      <EditIcon fontSize='large'
                      />
                    </Button>
                }
              </div>
            }

          </div>



          <div>
            {
              process.env.NODE_ENV === "Prod"
                ?
                ""
                :
                <Button style={listButtonStyle} onClick={() => {
                  setDeleteAllLevelConfirmation(true)
                }}>
                  <DeleteIcon />
                </Button>
            }
          </div>
        </Stack>
      </Stack>
    </Card >
  );
}
import DeleteConfirmationModal from "./DeleteConfirmationModal";

export function SuperHeroLevelDeleteConfirmation(props: any) {
  const {
    setOpenDelete,
    openDelete,
    levelId,
    levelNo,
    character_image_name,
    set_character_image,
    set_character_image_name,
    products,
    setProducts,
  } = props;
  const handleClose = () => {
    setOpenDelete({ state: false, index: 0 });
  };

  let splitted_image_name = "";
  const lastIndex = character_image_name.lastIndexOf("/");
  const after = character_image_name.slice(lastIndex + 1);
  splitted_image_name = after;

  const deleteConfirmed = async () => {

    set_character_image("");
    set_character_image_name("");

    let tempArray = []
    products.forEach(x => {
      if (x.id === levelId) {
        x.characterImageName = ""
        x.characterImage = ""
      }
      tempArray.push(x)
    })
    setProducts(tempArray)

    setOpenDelete({ state: false, index: 0 });
  };
  return (
    <DeleteConfirmationModal
      open={openDelete['state']}
      handleClose={handleClose}
      deleteConfirmed={deleteConfirmed}
      modalTitle={"Delete Confirmation"}
      modalContent={<span>
        Are you sure you want to delete image
        {""}<strong> {splitted_image_name} </strong>{""}
        from index <strong>{levelNo}</strong> ?
      </span>}
    />
  )
}

import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Drawer, Stack } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/scrollbar';
import Cookies from 'js-cookie';
import SideBarNav from './SideBarNav';
import SideRibbon from '../../../components/SideRibbon';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
import { LOGOUT_API } from '../../../constants';
import { isApiCodeSucess, postRequest } from '../../../data/helpers/services';
import { AccessTokenContext } from '../../../routes';
import PasswordModal from '../../../components/PasswordModal';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  let projectsListObject = useSelector((state: RootStateType) => state.projects);

  let uid = projectsListObject?.selectedProject?.uid || "";

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg', '');

  const toggleDrawer = () => {
    onCloseNav();
  }

  const logout = async () => {
    let res: any = {}

    res = await postRequest(
      LOGOUT_API,
      {},
      accessToken,
      ""
    )
    if (isApiCodeSucess(res)) {
      Cookies.remove('access_token');
      navigate('/login', { replace: true });
    }

  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>

        <img src="/assets/logoSkycap.svg"
          style={{
            width: "119px",
          }} 
          alt=''
          />

        <span style={{ color: '#2065D1', marginLeft: "10px", marginTop: "-5px", fontWeight: 600 }}>
          {process.env.REACT_APP_ENV}
        </span>
      </Box>

      <SideBarNav />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box>
            {
              (uid && (uid !== ""))
                ?
                <SideRibbon />
                :
                null
            }
          </Box>

          <Button style={{
            color: "#2065D1",
            fontWeight: "500",
            fontSize:16,
            marginTop: "10px",
            background:'transparent'
          }}
            onClick={() => setOpenPasswordModal(true)}
          >
            Edit Password
          </Button>

          <PasswordModal openPasswordModal={openPasswordModal} setOpenPasswordModal={setOpenPasswordModal} />
          <Button sx={{
            marginTop: "10px !important",
          }} 
          size='large'
          onClick={logout} 
          variant="contained">
            Logout
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH, xs: "5%" },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <>
          <Button onClick={() => toggleDrawer()}>
            <img src={'/assets/sidebar.svg'} style={{ position: "fixed", marginLeft: "-6%", width: "25px", marginTop: "5vh", height: "30px" }} alt="" />
          </Button>
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </Box>
  );
}

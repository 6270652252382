import { Box, Stack, Switch, TextField as MUITextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ZipLogo from '../pages/tinyLearner/ZipLogo';
import LevelIcon from '../static/LevelIcon'
import EditIcon from '../static/EditIcon'
import DeleteIcon from '../static/DeleteIcon'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon1 from '../static/UploadIcon1';
import CheckMark from '../static/CheckMark';
import CrossMark from '../static/CrossMark';
import CancelIcon from '../icons/CancelIcon';
import { cardListStyle, listButtonStyle } from '../theme/common-styles';

const GroupCardList = (props) => {
  const { id, updateGroupStatus, groupNameState, setGroupOpenDelete, levelZipCount, handleGroupNameChange, editableIndex, groupMediaName, groupIndex, group, isActive, handleFileSelect, setOpenDelete, zipSize, mediaUrlDownload, updateGroup, resetGroupEditIndex, editGroup } = props;

  return (
    <Stack>
      <div style={cardListStyle}>
        <Stack direction="row" sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', gap:4 }}>
          <div>
            <MUITextField
              id="outlined-basic__4"
              label="Group Name"
              variant="outlined"
              value={groupNameState}
              sx={{
                background: "#F6F6F9",
                borderRadius: "6px",
                width: 250
              }}
              onChange={handleGroupNameChange}
              type="text"
              disabled={editableIndex !== id}
            />
          </div>

          <div>
            <Link to={"/tinylearner/group/" + group.id} style={{
              textDecoration: "none",
              color: 'inherit',
              alignSelf: "center",
              cursor: "pointer",
            }}>
              <div style={{
                display: "flex",
                gap: 12, flexWrap:'nowrap'
              }}>
                <LevelIcon />
                <span>
                  Levels-{levelZipCount}
                </span>
              </div>
            </Link>
          </div>

          <div style={{
            alignSelf: "center"
          }}>
            <Switch
              checked={isActive}
              onChange={() => {
                updateGroupStatus(!isActive);
              }}
              color='secondary'
              sx={{
                "& .MuiSwitch-switchBase.Mui-disabled": {
                  color: 'green !important',
                  backgroundColor: 'rgb(187 219 178 / 16%) !important'
                },
              }}
            />
          </div>

          <div>
            {(!groupMediaName)
              ?
              (
                <Box component="div"
                  sx={{
                    width: '100px',
                    position: 'relative',
                    borderRadius: "16px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      position: 'absolute',
                      left: '10%',
                      top: '50%',
                      width: "60px"
                    }}
                  >
                    <UploadIcon1 />
                  </Box>

                  <Box
                    component="input"
                    type="file"
                    name="thumbnail"
                    onChange={handleFileSelect}
                    sx={{
                      height: '100%',
                      width: '100%',
                      opacity: '0',
                      cursor: 'pointer',
                      left: '50%'
                    }}
                    disabled={editableIndex !== id}
                  ></Box>
                </Box>
              )
              :
              (
                <Box className="img--uploadBg" style={{
                  minHeight: '55px',
                }}>
                  <div
                    style={{
                      position: 'relative',
                      display: 'inline-block',
                    }}
                  >
                    <ZipLogo username={"username"} logoText={groupIndex} />
                    <span style={{ whiteSpace: "nowrap" }}>
                      {zipSize}
                    </span>
                    {
                      (editableIndex === id) ?

                        <Button style={{ position: "absolute", top: 0, right: 0, background: "transparent" }} onClick={() => {
                          setOpenDelete({
                            state: true,
                            index: id,
                          })
                        }}>
                          <CancelIcon />
                        </Button>
                        : ""
                    }
                  </div>
                </Box>
              )
            }
          </div>


          <div style={{display:'flex', gap:8, alignItems:'center'}}>
            {
              (!groupMediaName) ? ""
                :
                <Link to={mediaUrlDownload} target="_blank" download>
                  <Button
                    style={{...listButtonStyle,color:'#C7CBD6'}}>
                    <FileDownloadIcon
                    />
                  </Button>
                </Link>
            }
            {
              (editableIndex === id)
                ?
                <>
                  <Button
                    style={listButtonStyle}
                    onClick={() => {
                      updateGroup(id);
                    }}>
                    <CheckMark />
                  </Button>
                  <Button style={{
                    background: 'transparent',
                    minWidth:'fit-content'
                  }}
                    onClick={() => {
                      resetGroupEditIndex(id);
                    }}>
                    <CrossMark />
                  </Button>
                </>
                :
                <>
                  <Button
                    style={listButtonStyle}
                    onClick={() =>
                      editGroup(id)
                    }>
                    <EditIcon />
                  </Button>
                  <Button style={{
                    background: 'transparent',
                    minWidth:'fit-content'
                  }}
                    onClick={() => {
                      setGroupOpenDelete({
                        state: true,
                        index: id,
                      })
                    }}>
                    <DeleteIcon />
                  </Button>
                </>
            }
          </div>
        </Stack>
      </div>


    </Stack>
  )
}

export default GroupCardList
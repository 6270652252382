import { ReactSortable } from "react-sortablejs"
import { TextField as MUITextField, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import LevelZipUpload from '../components/LevelZipUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { cardListStyle, listButtonStyle } from "../theme/common-styles";
import useResponsive from "../hooks/useResponsive";

const GroupDetailList = (props) => {
  const mdUp = useResponsive('up', 'md', '');
  const { draggableGroupList, setDraggableGroupList, groupId, groupIndex } = props;
  return (
    <div>
      <ReactSortable style={{
        display: "grid",
        gridTemplateColumns: "repeat(1,1fr)",
        gridColumnGap: "1rem",
        marginTop: "25px",
        gap: "25px",
      }} list={draggableGroupList}
        setList={setDraggableGroupList}>

        {draggableGroupList.map((level) => (
          <div key={level.id} style={cardListStyle}>
            <Stack direction="row" sx={{ flexWrap: "wrap", alignItems: 'center', justifyContent: "space-around", gap: mdUp? 1:2, width: "100%" }}>
              <MUITextField
                id="outlined-basic"
                label="color1"
                variant="outlined"
                value={level.color1}
                sx={{
                  background: "#F5F6F9",
                  borderRadius: "8px",
                  flexShrink: 0,
                  width: mdUp ? "9%" : "90px"
                }}
                type="text"
                disabled
              />

              <MUITextField
                id="outlined-basic"
                label="color2"
                variant="outlined"
                value={level.color2}
                sx={{
                  background: "#F5F6F9",
                  borderRadius: "8px",
                  flexShrink: 0,
                  width: mdUp ? "9%" : "90px"
                }}
                type="text"
                disabled
              />

              <MUITextField
                id="outlined-basic"
                label="color3"
                variant="outlined"
                value={level.color3}
                sx={{
                  background: "#F5F6F9",
                  borderRadius: "8px",
                  flexShrink: 0,
                  width: mdUp ? "9%" : "90px"
                }}
                type="text"
                disabled
              />

              <MUITextField
                id="outlined-basic"
                label="coins"
                variant="outlined"
                value={level.coins}
                sx={{
                  background: "#F5F6F9",
                  borderRadius: "8px",
                  flexShrink: 0,
                  width: mdUp ? "9%" : "90px"
                }}
                type="text"
                disabled
              />

              <MUITextField
                id="outlined-basic"
                label="name"
                variant="outlined"
                value={level.name}
                sx={{
                  background: "#F5F6F9",
                  borderRadius: "8px",
                  flexShrink: 0,
                  width: mdUp ? "15%" : "110px"
                }}
                type="text"
                disabled
              />

              <MUITextField
                id="outlined-basic"
                label="File name"
                variant="outlined"
                value={level.level_name}
                sx={{
                  background: "#F5F6F9",
                  borderRadius: "8px",
                  flexShrink: 0,
                  width: mdUp ? "15%" : "120px"
                }}
                type="text"
                disabled
              />

              <div style={{ width: mdUp ? "15%" : "120px" }}>
                <LevelZipUpload
                  levelName={level.level_name}
                  groupId={groupId}
                  groupIndex={groupIndex}
                  levelId={level.id}
                  levelMediaName={level.level_media_name ? level.level_media_name : ""}
                  levelMediaPath={level.level_media_path ? level.level_media_path : ""}
                  levelIndex={level.level_no ? level.level_no : ""}
                  zipSize={level.zip_size}
                />
              </div>
              <div style={{ width: mdUp ? "3%" : "90px" }}>
                {
                  (!level.level_media_name) ? ""
                    :
                    <Link to={level.level_media_path} target="_blank" download>
                      <Button style={{ ...listButtonStyle, color: '#C7CBD6' }} >
                        <FileDownloadIcon />
                      </Button>
                    </Link>
                }
              </div>
            </Stack>
          </div>
        ))}
      </ReactSortable>
    </div>
  )
}

export default GroupDetailList
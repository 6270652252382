import { Box, Button } from '@mui/material';
import ZipLogo from '../pages/tinyLearner/ZipLogo';
import CancelIcon from '../icons/CancelIcon';

const ZipUploadPreviewBox = (props) => {
  const { zipObj, handleFileRemove } = props;
  return (
    (
      <Box className="img--uploadBg" style={{
        minHeight: '100px',
        maxHeight: '100px'
      }}>
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            marginTop: "12px"
          }}

        >
          <ZipLogo
            username={"username"}
            logoText={zipObj.group_index ? zipObj.group_index : ""}
          />
          {
            (!zipObj.uploaded)
              ?
              ""
              :
              <Button style={{ background: 'transparent' }} onClick={handleFileRemove}><CancelIcon /></Button>
          }
        </div>
      </Box>
    )
  )
}

export default ZipUploadPreviewBox
import { Grid } from '@mui/material';
import GroupCard from './GroupCard';
import { ReactSortable } from "react-sortablejs";
import { useEffect, useState } from 'react';
import { getChangedValues } from '../../../utils/common-function';

// ----------------------------------------------------------------------

export default function GroupList({ groups, setGroupList, setUpdateValues, draggableGroupList, setDraggableGroupList, ...other }) {
  const [editableIndex, setEditableIndex] = useState("-1");

  useEffect(() => {
    setDraggableGroupList(groups)
  }, [groups])


  useEffect(() => {
    let updatedValues = getChangedValues(groups, draggableGroupList)
    if (updatedValues.length > 0) {
      setUpdateValues(updatedValues)
    } else {
      setUpdateValues([])
    }
  }, [draggableGroupList])

  return (
      <ReactSortable style={{
        display: "grid",
        gap: "25px",
        gridTemplateColumns: "repeat(1, 1fr)",
        overflow: "auto",
        paddingBottom:32
      }} list={draggableGroupList} 
      setList={setDraggableGroupList}>

        {draggableGroupList.map((group) => (
          <Grid
            key={group.id} item 
            xs={12} sm={6} 
            md={3}>
            <GroupCard
              group={group}
              editableIndex={editableIndex}
              setEditableIndex={setEditableIndex}
              groups={groups}
              setGroupList={setGroupList}
            />
          </Grid>
        ))}
      </ReactSortable>
  );
}


import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LevelPositionDeleteConfirmation } from '../components/LevelPositionDeleteConfirmation';
import LevelPositionList from '../components/LevelPositionList';

const LevelPositionForm = (props) => {
  const { initialForm, handleSubmit, deletionProps, EditButtonToggle, editable, AddAnotherRow, imageProps, setOpenDelete, uid } = props;
  return (
    <Form
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators
      }}
      initialValues={{ levelPositions: initialForm }}
      onSubmit={handleSubmit}>
      {
        (props: any) => (
          <form onSubmit={props.handleSubmit}>
            <FieldArray name="levelPositions">
              {({ fields }) => (
                <>

                  <LevelPositionDeleteConfirmation {...deletionProps} fields={fields} />
                  <div
                    className='row'
                    style={{
                      display: 'flex',
                      marginBottom: "24px"
                    }}
                  >
                    <div style={{
                      width: '60%',
                      display:'flex',
                      gap:8,
                      alignItems:'center'
                    }}>
                       <h2 style={{margin:0, marginLeft:12}}>Level Detail</h2>
                      {
                        editable
                          ?
                          <button
                            style={{ background: "none", border: "none", cursor: "pointer" }}
                            type="button"
                            onClick={() => AddAnotherRow(fields)}
                          >
                            <AddCircleIcon sx={{ color: "green" }} fontSize={'large'} />
                          </button>

                          :
                          ""}
                    </div>
                    <div style={{
                      width: '40%'
                    }}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => { EditButtonToggle() }}
                        style={{
                          float: 'right',
                          marginRight: "20px"
                        }}
                      >
                        {editable ? "Cancel" : "Edit"}
                      </Button>
                    </div>
                  </div>
                  <div className='row'
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1,1fr)",
                      gridColumnGap: "1rem",
                      overflow: "auto"
                    }}
                  >
                    {fields.map((name, index) => (
                      <LevelPositionList
                        key={name}
                        name={name}
                        editable={editable}
                        imageProps={imageProps}
                        index={index}
                        uid={uid}
                        fields={fields}
                        setOpenDelete={setOpenDelete}
                      />
                    ))}
                  </div>
                </>
              )}

            </FieldArray>

            {
              editable ?
                <div className="button-section">
                  <Button type="submit" size='large' variant="contained">
                    Submit
                  </Button>
                </div>
                : ""
            }
          </form>
        )
      }
    </Form>
  )
}

export default LevelPositionForm
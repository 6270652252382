import { Box } from "@mui/material"
import UploadIcon from "../icons/UploadIcon"

const RenderUploadBox = (props) => {
  const {handleFileSelect} = props;
  return (
  <Box component="div"
    sx={{
      width: '20%',
      background: '#232157',
      marginBottom: "20px",
      position: 'relative',
      borderRadius: "15px",
      cursor: "pointer",
      aspectRatio: '4/3'
    }}
  >
    <Box
      component="div"
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      <UploadIcon />
    </Box>
    <Box
      component="input"
      type="file"
      name="thumbnail"
      onChange={handleFileSelect}
      sx={{
        height: '100%',
        width: '100%',
        opacity: '0',
        cursor: 'pointer',
      }}
    ></Box>
  </Box>
  )
}

export default RenderUploadBox
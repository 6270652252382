import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'mui-rff';
import { createLevel } from '../data/mappers/createLevel';
import { LEVELS, LEVEL_CREATE_API, LEVEL_DETAIL_API } from '../constants';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { AccessTokenContext } from '../routes';
import LevelFormImageUpload from '../components/LevelFormImageUpload';
import BackButton from '../components/BackButton';
import { cardListStyle } from '../theme/common-styles';

export default function LevelFrom(props: any) {
    const { modeType } = props;
    const { uid } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken


    const [isNewLevel, setIsNewLevel] = useState(true)

    const { levelId } = useParams();
    const initialFormData = {
        level: "",
        scale: "",
        level_id: ""
    }
    const [initialForm, setInitialForm] = useState(initialFormData);

    const [imageObj, setImageObj] = useState({
        uploadStarted: false,
        uploaded: false,
        media: ""
    })


    useEffect(() => {
        if (modeType === 'add') {
            getAllLevelList();
        }
        else if (modeType === 'edit') {
            getLevelDetail();
        }
    }, []);

    const getAllLevelList = async () => {
        let res: any = {}

        let version = localStorage.getItem('level_version')
        res = await postRequest(LEVELS, {
            uid: uid
        }, accessToken,
            (version ?? "")
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data.level_list
                let newlevel = 0
                if (ContentData.length === 0) {
                    newlevel = 1
                } else {
                    newlevel = (ContentData[ContentData.length - 1]).level_no + 1

                }
                setInitialForm({ ...initialForm, level: `${newlevel}` })
            }
        }
    }

    const getLevelDetail = async () => {
        let res: any = {}

        let version = localStorage.getItem('level_version')

        res = await postRequest(LEVEL_DETAIL_API, {
            level_id: levelId,
            uid: uid
        }, accessToken,
            (version ?? "")
        );


        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data
                setInitialForm({
                    scale: `${ContentData.scaling}`,
                    level: ContentData.level_no,
                    level_id: ContentData.id
                })
                setIsNewLevel(ContentData.is_new_level)
                setImageObj({
                    uploaded: true,
                    uploadStarted: false,
                    media: ContentData.character_image_name
                })
            }
        }
    }

    let handleSubmit = async (event) => {
        if (!imageObj.media) {
            dispatch(ShowErrorAlert({ visible: true, message: "Character image is required" }));
        } else {
            dispatch(ShowProgressBar());
            let data = createLevel(event, imageObj.media, modeType, isNewLevel, uid);

            let response: any = null;
            let version = localStorage.getItem('level_version')

            response = await postRequest(LEVEL_CREATE_API, data, accessToken, (version ?? ""));

            if (response) {
                dispatch(HideProgressBar());
                if (isApiCodeSucess(response)) {
                    const resData = response.data
                    dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                    if (uid) {
                        navigate(`/dashboard/${uid}/${version}/level`)
                    } else {
                        navigate('/dashboard/level/list/' + version)
                    }
                }
            }
        }
    }


    const imageProps = {
        imageObj: imageObj,
        setImageObj: setImageObj,
        uid: uid
    }

    return (
        <div>
            <BackButton />
            <Form
                initialValues={{ ...initialForm }}
                onSubmit={handleSubmit}>
                {
                    (props: any) => (
                        <form onSubmit={props.handleSubmit} style={{ ...cardListStyle,gap:24,marginLeft:50, maxWidth: 800, padding:40, display:'flex', flexDirection:'column'}}>
                            <h2 style={{margin:0}}>Create Level</h2>
                            <input type="hidden" name={`level_id`} />
                            <TextField
                                style={{ width: "100%" }}
                                label="Level" name={`level`}
                                sx={{
                                    width: "25%",
                                }} type="number"
                                required={true}
                                disabled
                            />

                            <TextField
                                style={{ width: "100%" }}
                                label="Scale" name={`scale`}
                                sx={{
                                    width: "25%",
                                }}
                                type="number"
                                required={true}
                            />
                            <LevelFormImageUpload {...imageProps} />

                            <div className="button-section"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Button type="submit" size='large' variant="contained">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    )
                }
            </Form>
        </div>
    )
}
import { Helmet } from 'react-helmet-async';
import useResponsive from '../hooks/useResponsive';
import { LoginForm } from '../sections/auth/login';
import { envTextStyle } from "../theme/common-styles"


const leftStyle = {
  padding: "2rem",
  alignItems: "center",
  backgroundImage: "linear-gradient(321deg, #fafbfc 17.84%, #e3ecf2 79.27%)",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
} as any;


const rightStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  padding: "0 2rem",
} as any

export default function LoginPage() {
  const mdUp = useResponsive('up', 'lg', '');
  const smUp = useResponsive('up', 'sm', '');

  return (
    <>
      <Helmet>
        <title> Login | Minimal UI </title>
      </Helmet>

      <section style={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column' }}>
        <div style={{ ...leftStyle, height: mdUp ? "100vh" : "", width: mdUp ? "40%" : "100%", }}>
          <img src="/logoSkycap.svg" alt="login" style={{ maxWidth: "300px", marginInline: 'auto' }} />
          <span style={envTextStyle}>
            {process.env.REACT_APP_ENV}
          </span>
        </div>

        <div style={{ ...rightStyle, height: mdUp ? "100vh" : "calc(100vh - 145px)", width: mdUp ? "60%" : "100%", }}>
          <div style={{ width: mdUp ? 500 : smUp ? 450 : 290 }}>
            <LoginForm />
          </div>
        </div>
      </section>
    </>
  );
}

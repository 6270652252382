import { useDispatch } from "react-redux";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { isApiCodeSucess, tlPostRequest } from "../data/helpers/services";
import { TinyLearnerEndpoints } from "../constants/endpoints";
import { ShowSuccessAlert } from "../store/alerts";
export function GroupDeleteConfirmation(props: any) {

    const {
        charId,
        setGroupOpenDelete,
        groupOpenDelete,
        accessToken,
    } = props;

    const handleClose = () => {
        setGroupOpenDelete({ state: false, index: 0 });
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deleteConfirmed = async () => {
        const res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_DELETE,
            {
                "group_id": charId,
            },
            accessToken
        )
        console.log("res", res)
        if (res) {
            if (isApiCodeSucess(res)) {
                dispatch(ShowSuccessAlert({
                    visible: true,
                    message: `${res.data.message}`
                }));
                navigate('/tinylearner/group')
            } else {
                setGroupOpenDelete({ state: false, index: 0 });
            }
        }

    };
    return (
        <DeleteConfirmationModal
            open={groupOpenDelete['state']}
            handleClose={handleClose}
            deleteConfirmed={deleteConfirmed}
            modalTitle={"Delete Confirmation"}
            modalContent={<span>
                Are you sure you want to delete this group ?
            </span>}
        />
    )
}

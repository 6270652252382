import { useContext, useState } from 'react'
import { Box, Button } from '@mui/material';
import UploadIcon from '../icons/UploadIcon';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import Compressor from 'compressorjs';
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { useDispatch } from 'react-redux';
import { postRequest } from '../data/helpers/services';
import { LEVEL_CREATE_API } from '../constants';
import { createLevel } from '../data/mappers/createLevel';
import { AccessTokenContext } from '../routes';
import { BASE_URL_CLONE, BASE_URL, s3Client, S3_BUCKET } from "../constants/s3Bucket"
import { SuperHeroLevelDeleteConfirmation } from './SuperHeroLevelDeleteConfirmation';
import CancelIcon from '../icons/CancelIcon';

export default function SuperHeroLevelImageUploadOnS3(props: any) {
    const {
        levelId,
        character_image,
        set_character_image,
        character_image_name,
        set_character_image_name,
        levelNo,
        scale,
        isNewLevel,
        editableIndex,
        products,
        setProducts,
        uid
    } = props;
    const dispatch = useDispatch();

    const getCookieAccessToken: any = useContext(AccessTokenContext);
    let accessToken: string = getCookieAccessToken.cookieAccessToken;


    const [openDelete, setOpenDelete] = useState<{
        state: boolean,
        index: number
    }>({ state: false, index: 0 })


    const uploadFile = async (file) => {
        new Compressor(file, {
            quality: 0.25, // Compression quality (0.8 = 80% compression)
            success: (compressedFile) => {
                s3UploadFile(compressedFile);
            },
            error: (error) => {
                console.log('Error compressing file:', error);
            },
        });
    };

    const s3UploadFile = async (file) => {

        if (file.name.includes(" ")) {
            dispatch(ShowErrorAlert({
                visible: true,
                message: "Image file name contains spaces kindly remove"
            }));
            return false
        }
        let folder = `World/${file.name}`
        let key = "superhero/" + folder
        if (uid) {
            key = `V3_Clone/${uid}/${folder}`
        }

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: key,
            ContentType: 'image/jpeg',
        }

        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                let uploaded_zip_name = "";
                const lastIndex = folder.lastIndexOf("/");
                const after = folder.slice(lastIndex + 1);
                uploaded_zip_name = after;


                let response: any = null;
                const event = {
                    "level_id": `${levelId}`,
                    "media_path_name": `${folder}`,
                    "media_path": `${BASE_URL}${folder}`,
                    "level": levelNo,
                    "scale": scale
                }
                let data = createLevel(
                    event,
                    folder,
                    "edit",
                    isNewLevel,
                    uid
                );

                let version = localStorage.getItem('level_version')

                response = await postRequest(
                    LEVEL_CREATE_API,
                    data,
                    accessToken,
                    (version ?? "")
                );
                if (response) {
                    dispatch(HideProgressBar());
                }

                dispatch(ShowSuccessAlert({
                    visible: true,
                    message: `${uploaded_zip_name} Uploaded successfully.`
                }));

                let tempArray = []
                products.forEach(x => {
                    if (x.id === levelId) {
                        x.characterImageName = `${folder}`

                        x.characterImage =
                            uid
                                ?
                                `${BASE_URL_CLONE}${uid}/${folder}`
                                :
                                `${BASE_URL}${folder}`
                    }
                    tempArray.push(x)
                })
                setProducts(tempArray)

                set_character_image(
                    uid
                        ?
                        `${BASE_URL_CLONE}${uid}/${folder}`
                        :
                        `${BASE_URL}${folder}`
                );
                set_character_image_name(folder);
            }
        }
        catch (err) {
            console.log(err)
        }

        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };


    const deletionProps = {
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        levelId: levelId,
        levelNo: levelNo,
        character_image_name: character_image_name,
        set_character_image: set_character_image,
        set_character_image_name: set_character_image_name,
        accessToken: accessToken,
        dispatch: dispatch,
        products: products,
        setProducts: setProducts,
        uid: uid
    }

    return (
        <div className="col-12 md-col-4"
        >
            {
                !character_image
                    ?
                    (
                        <Box component="div"
                            sx={{
                                width: '100px',
                                background: '#232157',
                                marginBottom: "20px",
                                position: 'relative',
                                borderRadius: "15px",
                                cursor: "pointer",
                                aspectRatio: '4/3',
                                marginLeft: '40px'
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    width: "60px"
                                }}
                            >
                                <UploadIcon />
                            </Box>

                            <Box
                                component="input"
                                type="file"
                                name="thumbnail"
                                disabled={editableIndex !== levelId}
                                onChange={handleFileSelect}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    opacity: '0',
                                    cursor: 'pointer',
                                    left: '50%'
                                }}
                            ></Box>
                        </Box>
                    )
                    :
                    <>
                        <SuperHeroLevelDeleteConfirmation
                            {...deletionProps}
                        />
                        <Box className="img--uploadBg" style={{
                            minHeight: '100px',
                            maxHeight: '100px'
                        }}>

                            <div
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                }}

                            >
                                <div className="row" style={{position:'relative'}}>

                                    <img
                                        src={character_image}
                                        style={{
                                            height: '95px',
                                            width: '95px',
                                            objectFit:'contain'
                                        }}
                                        alt=''
                                    />

                                    {
                                        (editableIndex === levelId) ?
                                        <Button style={{background:"transparent", position:'absolute',top:5,right:15}} 
                                        onClick={() => {
                                            setOpenDelete({
                                                state: true,
                                                index: levelId,
                                            })
                                        }}><CancelIcon/></Button>
                                            : ""
                                    }
                                </div>

                            </div>
                        </Box>
                    </>
            }
        </div>
    )
}



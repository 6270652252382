import { useState } from "react";

export const usePasswordValidation = () => {
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handlePasswordChange = (event) => {
    let updatedPassword = event.target.value;
    setPasswordValue(updatedPassword);

    if (confirmPasswordValue) {
      if (confirmPasswordValue !== updatedPassword) {
        setConfirmPasswordError("Password does not match")
      } else {
        setConfirmPasswordError("")
      }
    }
  };


  const handleConfirmPasswordChange = (event) => {
    let updatedConfirmPassword = event.target.value;
    if (passwordValue !== updatedConfirmPassword) {
      setConfirmPasswordError("Password does not match")
    } else {
      setConfirmPasswordValue(updatedConfirmPassword);
      setConfirmPasswordError("")
    }
  };
  return {
    passwordValue,
    confirmPasswordValue,
    confirmPasswordError,
    handlePasswordChange,
    handleConfirmPasswordChange
  }
}
import { useSelector } from 'react-redux';
import { RootStateType } from '../store';

export default function SideRibbon(props:any) {

  let projectsListObject = useSelector((state: RootStateType) => state.projects);
  let projectName = projectsListObject?.selectedProject?.projectName || "";

  return (
    <div style={{
      display: 'block',
      borderRadius:4,
      width: '225px',
      padding: '15px',
      backgroundColor: 'rgb(15 111 175 / 15%)',
      color: 'rgb(15 111 175 )',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '1',
      textAlign: 'center',
      cursor:'not-allowed',
      pointerEvents:'none'
    }}>
      <span>{projectName}</span>
    </div>
  // </div>
  )
}

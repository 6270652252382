import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { createLevelPosition } from '../data/mappers/createLevelPositions';
import { LEVEL_CHARACTER_POSITION_API, CHARACTER_POSITION_LIST_API } from '../constants';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { AccessTokenContext } from '../routes';
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import YarlNavigationPageStyles from '../theme/YarlNavigationPageStyles';

import LevelPositionForm from '../components/LevelPositionForm';
import BackButton from '../components/BackButton';


export default function LevelPositions(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const getCookieAccessToken: any = useContext(AccessTokenContext)

    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const [editable, setEditable] = useState(false)

    const { uid, levelId } = useParams();
    const [levelNo, setLevelNo] = useState("");

    const [open, setOpen] = useState({ state: false, media: "" })
    const [openDelete, setOpenDelete] = useState<{ state: boolean, index: number }>({ state: false, index: 0 })

    let initialFormValues = {
        "char_id": null,
        "x_move": null,
        "y_move": null,
        "index": 1,
        "real_x_position": null,
        "real_y_position": null
    }

    const [initialForm, setInitialForm] = useState([])
    const [imageArray, setImageArray] = useState([])

    const [responseFetched, setResponseFetched] = useState(false)

    useEffect(() => {
        getCharacterPositions()
    }, [])

    const getCharacterPositions = async () => {
        let res: any = {}

        let version = localStorage.getItem('level_version')

        res = await postRequest(CHARACTER_POSITION_LIST_API, {
            level_id: levelId,
            uid: uid
        }, accessToken,
            (version ?? "")
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let responseData = res.data.data
                let responseArray = responseData.charcter_list_data
                setLevelNo(responseData.level_no)
                if (responseArray.length > 0) {
                    responseArray.forEach((resObj, index) => {
                        let tempArray = initialForm
                        tempArray.push({
                            "char_id": resObj.id,
                            "x_move": resObj.x_move,
                            "y_move": resObj.y_move,
                            "index": resObj.index,
                            "real_x_position": resObj.real_x_position,
                            "real_y_position": resObj.real_y_position
                        })

                        let tempImageArray: any = imageArray

                        tempImageArray.push({
                            Id: `levelPositions[${index}].foreground`,
                            uploadStarted: false,
                            uploaded: resObj.fg_image,
                            media: `${resObj.fg_image}`
                        });

                        tempImageArray.push({
                            Id: `levelPositions[${index}].background`,
                            uploadStarted: false,
                            uploaded: resObj.bg_image,
                            media: `${resObj.bg_image}`
                        });
                        setImageArray(tempImageArray)

                        setInitialForm(tempArray)
                    })
                }
                else {
                    setInitialForm([{ ...initialFormValues }])
                    let tempImageArray = []
                    tempImageArray.push({ Id: `levelPositions[0].foreground`, uploadStarted: false, uploaded: false, media: "" });
                    tempImageArray.push({ Id: `levelPositions[0].background`, uploadStarted: false, uploaded: false, media: "" });
                    setImageArray(tempImageArray)
                }
            }

            setResponseFetched(true)
        }
    }

    const verifyIndexFailed = (event) => {
        let isDuplicate = false
        let tempIndexArray: number[] = []
        event.levelPositions.map(
            (obj) => {
                if (tempIndexArray.includes(parseInt(obj.index))) {
                    dispatch(ShowErrorAlert({ visible: true, message: `Index ${obj.index} used multiple times` }))
                    isDuplicate = true;
                } else {
                    tempIndexArray.push(parseInt(obj.index));
                }
            }
        )
        return isDuplicate;
    }

    const verifyImageNotUploaded = (event) => {
        let failed = false
        for (let i = 0; i < event.levelPositions.length; i++) {
            // FG IMAGE
            if (!imageArray[i * 2].media) {
                failed = true;
                dispatch(ShowErrorAlert({ visible: true, message: `Forground image for index ${event.levelPositions[i].index} is missing` }))
                break;

            }

            // BG IMAGE
            if (!imageArray[i * 2 + 1].media) {
                failed = true;
                dispatch(ShowErrorAlert({ visible: true, message: `Background image for index ${event.levelPositions[i].index} is missing` }))
                break;
            }
        }
        return failed
    }

    let handleSubmit = async (event) => {
        dispatch(ShowProgressBar());
        let duplicateIndex = verifyIndexFailed(event)
        let verifyNotAllImage = verifyImageNotUploaded(event)

        if (duplicateIndex || verifyNotAllImage) {
            dispatch(HideProgressBar());
            return true;
        }
        else {
            let data = createLevelPosition(event, levelId, imageArray, uid);
            let response: any = null;

            let version = localStorage.getItem('level_version')

            response = await postRequest(LEVEL_CHARACTER_POSITION_API, data, accessToken,
                (version ?? "")
            );
            if (response) {
                dispatch(HideProgressBar());
                if (isApiCodeSucess(response)) {
                    getCharacterPositions();
                    navigate(0)
                }

            }
        }
    }

    const imageProps = {
        imageArray: imageArray,
        setImageArray: setImageArray,
        levelNo: levelNo,
        setOpen: setOpen,
        accessToken: accessToken,
        ediatble: editable
    }
    const AddAnotherRow = (fields) => {
        let index = 1
        if (fields.value) {
            index = fields.value[fields.value.length - 1].index + 1
        }
        fields.push({ ...initialFormValues, index: index })
        let tempImageArray: any = []
        if (imageArray.length) {
            tempImageArray = imageArray
        }
        tempImageArray.push({ Id: `${fields.name}[${fields.length}].foreground`, uploadStarted: false, uploaded: false, media: "" });
        tempImageArray.push({ Id: `${fields.name}[${fields.length}].background`, uploadStarted: false, uploaded: false, media: "" });
        setImageArray(tempImageArray)
        dispatch(ShowSuccessAlert({ visible: true, message: "New row added" }));
    }


    const deletionProps = {
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        imageArray: imageArray,
        setImageArray: setImageArray,
        uid: uid
    }

    const EditButtonToggle = () => {
        if (editable) {
            navigate(0)
        }
        setEditable(!editable);
    }

    return (
        <div>
            <BackButton/>
            <YarlNavigationPageStyles />
            <Lightbox
                open={open['state']}
                close={() => setOpen({ state: false, media: "" })}
                slides={[
                    {
                        src: open['media'],
                        alt: "image 1",
                        width: 3840,
                        height: 2560,
                    }
                ]} />

            {responseFetched ?
                <LevelPositionForm
                    initialForm={initialForm}
                    handleSubmit={handleSubmit}
                    deletionProps={deletionProps}
                    EditButtonToggle={EditButtonToggle}
                    editable={editable}
                    AddAnotherRow={AddAnotherRow}
                    imageProps={imageProps}
                    setOpenDelete={setOpenDelete}
                    uid={uid}
                />
                : ""
            }
        </div>
    )
}

import { useContext, useState } from 'react'
import { Box, Button } from '@mui/material';
import { store } from '../store';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import { PutObjectCommand } from "@aws-sdk/client-s3";
import LevelZipLogo from '../pages/tinyLearner/LevelZipLogo';
import { AccessTokenContext } from '../routes';
import { TinyLearnerEndpoints } from '../constants';
import { tlPostRequest } from '../data/helpers/services';
import UploadIcon1 from '../static/UploadIcon1';
import { S3_BUCKET, s3Client, BASE_URL_TL } from "../constants/s3Bucket"
import { LevelZipUploadDeleteConfirmation } from './LevelZipUploadDeleteConfirmation';
import CancelIcon from '../icons/CancelIcon';

export default function LevelZipUpload(props: any) {
    let {
        levelName,
        groupIndex,
        levelId,
        levelMediaName,
        levelIndex,
        zipSize
    } = props;


    if (zipSize) {
        if (Number(zipSize) > 1024) {
            zipSize = `${(zipSize / 1024).toFixed(2)} MB`;
        }
        else {
            zipSize = `${zipSize} KB`
        }
    }

    const getCookieAccessToken: any = useContext(AccessTokenContext);
    let accessToken: string = getCookieAccessToken.cookieAccessToken;


    const [openDelete, setOpenDelete] = useState({ state: false, index: "" });

    let zip_name = "";
    if (levelMediaName) {
        const lastIndex = levelMediaName.lastIndexOf("/");
        const after = levelMediaName.slice(lastIndex + 1);
        zip_name = after;
    }

    const [levelMedia, setLevelMedia] = useState(zip_name);

    const [levelS3MediaName, setLevelS3MediaName] = useState(levelMediaName);

    const { dispatch } = store;

    const uploadFile = async (file) => {
        let filePath = file.name;
        let allowedExtensions = /(\.zip)$/i;
        if (!allowedExtensions.exec(filePath)) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Please upload file having extensions .zip only."
                })
            )
            return false
        }


        if (file.name.includes(" ")) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Zip file name contains spaces kindly remove"
                })
            );
            return false
        }
        let folder = `group_${groupIndex}/levels/${file.name}`

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: ("tiny_learner/group/" + folder),
            ContentType: 'application/zip',
        }

        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                let uploaded_zip_name = "";
                const lastIndex = folder.lastIndexOf("/");
                const after = folder.slice(lastIndex + 1);
                uploaded_zip_name = after;


                let response: any = null;
                const data = {
                    "level_id": `${levelId}`,
                    "media_path_name": `${folder}`,
                    "media_path": `${BASE_URL_TL}${folder}`
                }
                response = await tlPostRequest(
                    TinyLearnerEndpoints.GROUP_ADD_LEVEL_MEDIA,
                    data,
                    accessToken
                );
                if (response) {
                    dispatch(HideProgressBar());
                }

                dispatch(ShowSuccessAlert({
                    visible: true,
                    message: `${uploaded_zip_name} Uploaded successfully.`
                }));

                setLevelS3MediaName(folder);
                setLevelMedia(uploaded_zip_name);
            }
        }
        catch (err) {
            console.log(err)
        }

        return true
    };

    const handleFileSelect = async (event: any) => {
        if (levelName !== event.target.files[0].name.split(".zip")[0])
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Zip file name mismatched with allocated file name"
                })
            );
        else {
            await uploadFile(event.target.files[0])
        }

        return true;
    };


    const deletionProps = {
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        levelS3MediaName: levelS3MediaName,
        levelId: levelId,
        setLevelS3MediaName: setLevelS3MediaName,
        setLevelMedia: setLevelMedia
    }

    console.log("zipSize", zipSize)


    return (
        <>
            <LevelZipUploadDeleteConfirmation
                {...deletionProps}
            />

            <div className="col-12 md-col-4"
                style={{
                    width: "100%",
                    marginTop: "-2px",
                    // @ts-ignore
                    textAlign: "-webkit-center",
                }}
            >
                {(!levelMedia)
                    ?
                    (
                        <Box component="div"
                            sx={{
                                width: '100px',
                                marginLeft: "25%",
                                position: 'relative',
                                borderRadius: "15px",
                                cursor: "pointer",
                                aspectRatio: '4/3'
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    width: "60px"
                                }}
                            >
                                <UploadIcon1 />
                            </Box>

                            <Box
                                component="input"
                                type="file"
                                name="thumbnail"
                                onChange={handleFileSelect}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    opacity: '0',
                                    cursor: 'pointer',
                                    left: '50%'
                                }}
                            ></Box>
                        </Box>
                    )
                    :
                    (
                        <Box className="img--uploadBg" style={{
                            height: '70px',
                        }}>
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    marginTop: "7%",
                                }}
                            >
                                <LevelZipLogo
                                    username={"username"}
                                    logoText={levelIndex}
                                />
                                <Button style={{minWidth:'fit-content', position: 'absolute', top: 0, right: 0 }} onClick={() => {
                                    setOpenDelete({
                                        state: true,
                                        index: levelId,
                                    })
                                }}>
                                    <CancelIcon />
                                </Button>
                            </div>
                        </Box>
                    )
                }

                <label className='input--label'
                    htmlFor="thumbnail">
                    {
                        levelMedia
                            ?
                            <>
                                <span style={{
                                    display: "inline-block",
                                    width: "100%"
                                }}>
                                    {levelMedia}
                                </span>
                                <span style={{
                                    whiteSpace: "nowrap",
                                }}>
                                    {zipSize}
                                </span>
                            </>
                            :
                            <span>Upload File</span>
                    }
                </label>
            </div>
        </>
    )
}





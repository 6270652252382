export default function LevelIcon() {
  return (
    <div>

        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11C0 9.89543 0.895431 9 2 9H4C5.10457 9 6 9.89543 6 11V18C6 19.1046 5.10457 20 4 20H2C0.89543 20 0 19.1046 0 18V11Z" fill="#0040CD"/>
            <path d="M8 7C8 5.89543 8.89543 5 10 5H12C13.1046 5 14 5.89543 14 7V18C14 19.1046 13.1046 20 12 20H10C8.89543 20 8 19.1046 8 18V7Z" fill="#0040CD"/>
            <path d="M16 2C16 0.895431 16.8954 0 18 0H20C21.1046 0 22 0.895431 22 2V18C22 19.1046 21.1046 20 20 20H18C16.8954 20 16 19.1046 16 18V2Z" fill="#0040CD"/>
        </svg>
      
    </div>
  )
}

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  
  return (
    <div
    style={{
        marginTop: "-80px"
    }}
>
    <ArrowCircleLeftIcon
        fontSize="large"
        color="secondary"
        onClick={() => navigate(-1)}
        sx={{
            marginBottom: "10px",
            marginLeft: "10px",
            "&:hover": {
                color: "#103996",
                cursor: "pointer",
            }
        }}
    />
</div>
  )
}

export default BackButton
import { Box, Button, Modal } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../../routes';
import { AppVersionApis } from '../../constants';
import { useParams } from 'react-router-dom';
import {cancelIcon, styleAppVersion} from "../../theme/common-styles"
import {initialFormData} from "../../constants/initialData";
import ForAddUpdateForm from '../../components/ForAddUpdateForm';


export default function TinyVersionClone(props: any) {
  const dispatch = useDispatch();

  let { uid } = useParams();

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [initialForm, setInitialForm] = useState(initialFormData);

  useEffect(() => {
    getAppVersion()
  }, [open])

  const getAppVersion = async () => {
    let res: any = {}

    res = await postRequest(
      AppVersionApis.TL_APP_VERSION_LIST, {}, accessToken,
      "v2"
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let responseData = res.data.data
        setInitialForm({
          android_version: responseData.android_version,
          ios_version: responseData.ios_version
        })
      }
    }
  }

  const handleSubmit = async (e) => {
    dispatch(ShowProgressBar());
    let data = {
      android_version: e.android_version,
      ios_version: e.ios_version,
      uid: uid
    };

    let response: any = null;

    response = await postRequest(AppVersionApis.TL_APP_VERSION_UPDATE, data, accessToken, "v2");

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        handleClose()
      }
    }
  }
  return (
    <>
      <Button variant="contained" size='large' 
      sx={{
        fontWeight: "600", cursor: "pointer",
      }} 
      onClick={handleOpen}>Version</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAppVersion}>
          <CancelIcon
            onClick={handleClose}
            sx={cancelIcon} />
            <ForAddUpdateForm formTitle="Tiny App Versions"  initialForm={initialForm} handleSubmit={handleSubmit}/>
        </Box>
      </Modal>
    </>
  )
}

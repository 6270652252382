import Axios, { AxiosResponse } from 'axios';
import { BASE_PATH } from '../../constants';
import { store } from '../../store';
import { HideProgressBar, ShowErrorAlert } from '../../store/alerts';
import Cookies from 'js-cookie';


const handlePanelVersion = (ApiResponse: any) => {
    if (ApiResponse && ApiResponse.data.code === 200) {
        let current_panel_version = ApiResponse.data.data.current_panel_version
        if (current_panel_version) {

            if (localStorage.getItem('currentPanelVersion') && (localStorage.getItem('currentPanelVersion') !== current_panel_version)) {
                localStorage.setItem('currentPanelVersion', current_panel_version)
                window.location.reload();
            }
            else {
                localStorage.setItem('currentPanelVersion', current_panel_version)
            }
        }
    }
    return {};
};

export const postRequest = async (endpoint: string, body: any, token?: string, version?: string) => {
    endpoint = version ? `${version}/${endpoint}` : `v3/${endpoint}`;
    let returnResponse = Axios.post(getApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
    handlePanelVersion(await returnResponse)
    return returnResponse;
};

export const tlPostRequest = async (endpoint: string, body: any, token?: string) => {
    let returnResponse = Axios.post(getTLApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
    handlePanelVersion(await returnResponse)
    return returnResponse
};

export const tlPatchRequest = (endpoint: string, body: any, token?: string) => {
    return Axios.patch(
        getTLApiPath(endpoint),
        JSON.stringify(body),
        getConfigSetting(token ?? '')).catch(function (error: any) {
            handleErrorResponse(error);
        });
};

export const postFileRequest = (endpoint: string, body: any, token?: string) => {
    return Axios.post(getApiPath(endpoint), body, getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
};

export const putRequest = (endpoint: string, body: any, token?: string) => {
    return Axios.put(getApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
};

const getApiPath = (endpoint: string) => BASE_PATH + '/api/admin/' + endpoint;

const getConfigSetting = (token: string) => {
    const headers: any = {
        'content-type': 'application/json',
    };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return {
        headers: headers,
    };
};

export const getRequest = async (endpoint: string, token?: string) => {
    let returnResponse = Axios.get(getApiPath(endpoint), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
    handlePanelVersion(await returnResponse)
    return returnResponse;
};

const getTLApiPath = (endpoint: string) => BASE_PATH + '/api/tiny_learner/admin/v1/' + endpoint;

const handleErrorResponse = (error: any) => {
    const { dispatch } = store;
    if (error.code === "ERR_NETWORK") {
        dispatch(ShowErrorAlert({ visible: true, message: "Internet connection lost" }));
    }
    else if (error.response.status === 401) {
        dispatch(ShowErrorAlert({ visible: true, message: "Token Expired" }));
        Cookies.set('access_token', null, {
            expires: 7, // in days
            secure: true,
        });
        window.location.reload();
    }
    else if (error.response.status === 412) {
        dispatch(ShowErrorAlert({ visible: true, message: "User not found" }));
        Cookies.set('access_token', null, {
            expires: 7, // in days
            secure: true,
        });
        window.location.reload();
    }
    else if (error.response.data.code === 400 && error.response.data.message !== '') {
        dispatch(ShowErrorAlert({ visible: true, message: error.response.data.message }));
    } else {
        dispatch(ShowErrorAlert({ visible: true, message: error.message }));
    }
    dispatch(HideProgressBar());

};

export const isApiCodeSucess = (resp: AxiosResponse) => {
    const { dispatch } = store;

    if (resp?.data?.code === 200) {
        return resp?.data?.code === 200;
    } else if (resp?.data?.code === 400) {
        dispatch(ShowErrorAlert({ visible: true, message: resp.data.message }));
        return false;
    } else if (resp?.data?.code === 500) {
        dispatch(ShowErrorAlert({ visible: true, message: "Backend : " + resp.data.message }));
        return false;
    }
};

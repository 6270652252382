const CancelIcon = () => {
  return (
    <svg
    viewBox="0 0 24 24"
    style={{
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        width: '20px',
        height: '20px',
        cursor: 'pointer'
    }}>
    <path
        fill="#FF0000"
        d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
    />
</svg>
  )
}

export default CancelIcon
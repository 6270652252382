export default function CrossMark() {
  return (
    <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#F2585C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.25"
        d="M14 2L2 14M2 2l12 12"
      ></path>
    </svg>
    </div>
  )
}

import React from 'react';

export const ResetPasswordFormLazy = React.lazy(() => import('../sections/auth/login/ResetPasswordForm'));
export const DashboardLayoutLazy = React.lazy(() => import('../layouts/dashboard'));
export const SimpleLayoutLazy = React.lazy(() => import('../layouts/simple'));
export const ProductsPageLazy = React.lazy(() => import('../pages/ProductsPage'));
export const LoginPageLazy = React.lazy(() => import('../pages/LoginPage'));
export const Page404Lazy = React.lazy(() => import('../pages/Page404'));
export const LevelPositionsLazy = React.lazy(() => import('../pages/LevelPositions'));
export const LevelFormLazy = React.lazy(() => import('../pages/LevelForm'));
export const GroupsPageLazy = React.lazy(() => import('../pages/tinyLearner/GroupsPage'));
export const GroupFormLazy = React.lazy(() => import('../pages/tinyLearner/GroupForm'));
export const GroupDetailLazy = React.lazy(() => import('../pages/tinyLearner/GroupDetail'));
export const BaseAppVersionLazy = React.lazy(() => import('../pages/AppVersion/Base'));

import { useState } from 'react'
import TlAppVersion from './TlAppVersion'
import SupHerAppVersion from './SupHerAppVersion'


  

export default function BaseAppVersion() {
    const [activeTab, setActiveTab] = useState("1")

  return (
    <>
    <PageStyles />
    <div style={{display: 'flex', minHeight: '100%'}}>
        <div className="asideapp__linkwraper" style={{flexShrink: 0, width: '200px'}}>
            <ul className= {`${activeTab==="1" ? "choosen_app" : "other_app"}`} onClick={() => {setActiveTab("1")}}>
                SuperHero
            </ul>
            <ul className= {`${activeTab==="2" ? "choosen_app" : "other_app"}`} onClick={() => {setActiveTab("2")}}>
                TinyLearner
            </ul>
        </div>
        <div className="asideapp__contentwrapper" style={{flexGrow: 1}}>
           {
            (activeTab === "1") ?
                <SupHerAppVersion />
               :
                <TlAppVersion />
           }
        </div>
    </div>
    </>
  )
}



export function PageStyles() {
    return (
    <style>
        {`

            .choosen_app {
                width: 100%;
                background-color: #c3c3cf73;
                padding: 15px;
                border-radius: 6px;
                cursor: pointer;
            }

            .other_app, .other_app:hover {
                width: 100%;
                padding: 15px;
                border-radius: 6px;
                cursor: pointer;
            }

            .other_app:hover {
                background-color: #F0F2F4;
            }
        `}
    </style>
    )
}
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from "react-redux";
import { postRequest, isApiCodeSucess } from '../../../data/helpers/services';
import { UPDATE_RESET_PASSWORD } from '../../../constants';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../../store/alerts';
import Cookies from 'js-cookie';
import { usePasswordValidation } from '../../../hooks/usePasswordValidation';

export default function ResetPasswordFormSide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { confirmPasswordError,passwordValue, handlePasswordChange, handleConfirmPasswordChange } = usePasswordValidation();

  const handleSubmit = async (e) => {

    if (confirmPasswordError !== "") {
      dispatch(ShowErrorAlert({ visible: true, message: "Password does not match" }));
      return false;
    }

    if (passwordValue.length < 6) {
      dispatch(ShowErrorAlert({ visible: true, message: "Password should be of minimum 6 characters" }));
      return false;
    }

    dispatch(ShowProgressBar());
    let requestData = {
      password: passwordValue,
      uuid: uuid
    }

    let response: any = null;
    response = await postRequest(UPDATE_RESET_PASSWORD, requestData, "");

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        try {
          // After receiving the access token from the server
          Cookies.set('access_token', response.data.data.data.authorization.access_token, {
            expires: 7, // in days
            secure: true,
            // httpOnly: true,
          });
          Cookies.set('refresh_token', response.data.data.data.authorization.refresh_token, {
            expires: 7, // in days
            secure: true,
            // httpOnly: true,
          });
          dispatch(ShowSuccessAlert({ visible: true, message: "Logged in successfully." }));
          navigate('/dashboard/super_hero/v3/level/');
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  return (
    <>
      <Stack spacing={3} marginTop={'4%'} marginBottom={'5%'}>
        <TextField name="password"
          InputProps={{
            onChange: handlePasswordChange
          }}
          required={true}
          label="Password" />

        <TextField
          name="confirmPassword"
          label="confirmPassword"
          type="password"
          required={true}
          InputProps={{
            onChange: handleConfirmPasswordChange
          }}
          error={confirmPasswordError !== ""}
          helperText={confirmPasswordError}
        />
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
        Reset
      </LoadingButton>
    </>
  );
}


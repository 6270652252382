export function PageStyles() {
  return (
    <style>
      {`
        .css-dmfr2k:hover {
            background-color: #337417; !important;        
        }
    `}
    </style>
  )
}

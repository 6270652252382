import { PROJECTS_REDUX, RESET } from "../../constants";
import { projectInfoInterface, UpdateProjectsInfoType, ResetProjectsInfoType, UpdateSelectedProjectInfoType } from "./types";



export const SaveProjectsInfo = (payload: projectInfoInterface[]) : UpdateProjectsInfoType => ({
    type: PROJECTS_REDUX.UPDATE,
    payload: payload
});


export const SelectedProjectsInfo = (payload: any) : UpdateSelectedProjectInfoType => ({
    type: PROJECTS_REDUX.SELECTED_PROJECT,
    payload: payload
});

export const ResetUserInfo = () : ResetProjectsInfoType => ({
    type: RESET
});


import { Button } from "@mui/material"

const SubmitSectionButton = (props) => {
  const { label, type = "submit", variant = "contained" } = props;
  return (
    <div className="button-section"
      style={{
        marginTop: "30px",
      }}
    >
      <Button size="large" sx={{ float: "right" }} type={type} variant={variant}>
        {label}
      </Button>
    </div>
  )
}

export default SubmitSectionButton
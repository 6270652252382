import { store } from '../store';
import Compressor from 'compressorjs';
import { DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import RenderImageContent from '../components/RenderImageContent';
import { s3Client, S3_BUCKET } from "../constants/s3Bucket"
import { Field } from 'react-final-form'
import { ShowErrorAlert } from '../store/alerts';
function LevelFormImageUpload(props: any) {
  const { dispatch } = store;
  const { imageObj, setImageObj, uid } = props

  const uploadFile = async (file) => {
    new Compressor(file, {
      quality: 0.25, // Compression quality (0.8 = 80% compression)
      success: (compressedFile) => {
        s3UploadFile(compressedFile);
      },
      error: (error) => {
        console.log('Error compressing file:', error);
      },
    });
  };


  const s3UploadFile = async (file) => {
    if (file.name.includes(" ")) {
      dispatch(ShowErrorAlert({
        visible: true,
        message: "Image file name contains spaces kindly remove"
      }));
      return false
    }
    let folder = `World/${file.name}`
    let key = "superhero/" + folder
    if (uid) {
      key = `V3_Clone/${uid}/${folder}`
    }

    const input = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: key,
      ContentType: 'image/jpeg',
    }

    const command = new PutObjectCommand(input);

    try {
      let res = await s3Client.send(command);
      if (res && res['$metadata']['httpStatusCode'] === 200) {
        setImageObj({ ...imageObj, media: folder, uploaded: true, uploadStarted: false })
      }
    }
    catch (err) {
      console.log(err)
    }
    return true
  };

  const handleFileSelect = async (event: any) => {
    await uploadFile(event.target.files[0])
    return true;
  };

  const handleFileRemove = async (event: any) => {
    const input = {
      Bucket: S3_BUCKET,
      Key: imageObj.media
    }
    const command = new DeleteObjectCommand(input);
    await s3Client.send(command);
    setImageObj({ ...imageObj, uploadStarted: false, uploaded: false, media: "" })
  }



  return (
    <div
      className="col-12 md-col-4"
      style={{
        alignItems: "",
        justifyContent: "flex-start"
      }}
    >
      <label
        className='input--label'
        htmlFor="thumbnail"
      >
        Character image
      </label>
      <Field
        name={"character"}
        type="hidden"
        component="input"
        id="thumbnail"
        placeholder="thumbnail"
      />
      <Field
        name="id"
        type="hidden"
        component="input"
        id="id"
        placeholder="id"
      />

      <RenderImageContent
        imageObj={imageObj}
        uid={uid}
        handleFileSelect={handleFileSelect}
        handleFileRemove={handleFileRemove}
      />

    </div>
  )
}

export default LevelFormImageUpload
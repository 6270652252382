import { useContext, useEffect, useState } from 'react'
import { AppVersionApis } from '../../constants';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import {initialFormData, initialAppStoreFormData} from "../../constants/initialData";
import ForceAppUpdateForm from '../../components/ForceAppUpdateForm';
import ForAddUpdateForm from '../../components/ForAddUpdateForm';


export default function SupHerAppVersion() {  
  const dispatch = useDispatch();

  const getCookieAccessToken:any = useContext(AccessTokenContext)
  let accessToken:string = getCookieAccessToken.cookieAccessToken

  const [initialForm, setInitialForm] = useState(initialFormData);
  const [initialAppStoreForm, setInitialAppStoreForm] = useState(initialAppStoreFormData);


  useEffect(()=>{
    getAppVersion()
  },[])

  const getAppVersion = async() =>{
      let res:any = {}
  
      res = await postRequest(
          AppVersionApis.SUPERHERO_APP_VERSION_LIST, "" , accessToken);
      if (res) {
          if (isApiCodeSucess(res)) {
              let responseData = res.data.data

              setInitialForm({
                android_version: responseData.android_version,
                ios_version: responseData.ios_version
              })

              setInitialAppStoreForm({
                android_store_version: responseData.android_store_version,
                ios_store_version: responseData.ios_store_version,
              })
          }
      }
  }

  const handleSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
        android_version: e.android_version,
        ios_version: e.ios_version
      };

    let response: any = null;

    response = await postRequest(AppVersionApis.SUPERHERO_APP_VERSION_UPDATE, data, accessToken);

    if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            const resData = response.data
            dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        }
    }
  }

  const handleStoreAppSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
        android_version: e.android_store_version,
        ios_version: e.ios_store_version,
        title: "New Version avialable",
        description: "New Version avialable",
      };

    let response: any = null;
    response = await postRequest(AppVersionApis.SUPERHERO_APP_STORE_VERSION_UPDATE, data, accessToken);

    if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            const resData = response.data
            dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        }
    }
  }

  return (
    <>

    <ForAddUpdateForm
     initialForm={initialForm}
     handleSubmit={handleSubmit}
     formTitle="For Ads"
     />
     
    <ForceAppUpdateForm
      initialAppStoreForm={initialAppStoreForm} 
      handleStoreAppSubmit={handleStoreAppSubmit}
      formTitle="Force update"
    />
    </>
  )
}
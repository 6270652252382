import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Button, Container, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { ProductList } from '../sections/@dashboard/products';
import { LEVELS, LEVEL_INDEX_UPDATE_API, UPDATE_LEVELS_AVIALABLE } from '../constants';
import { AccessTokenContext } from '../routes';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { levelIndexUpdate } from '../data/mappers/createLevel';
import { HideProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch, useSelector } from 'react-redux';
import PushPopUpModal from '../components/PushPopUpModal';
import JigsawAppVersion from './JigsawAppVersion';
import { RootStateType } from '../store';
import { SelectedProjectsInfo } from '../store/Projects';
import { PageStyles } from '../components/PageStyles';
import SaveCancelButtonGroup from '../components/SaveCancelButtonGroup';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateValues, setUpdateValues] = useState([]);

  const { uid, version } = useParams();

  if (localStorage.getItem('level_version') && localStorage.getItem('level_version') !== version) {
    localStorage.setItem('level_version', version)
  }

  let projectsListObject = useSelector((state: RootStateType) => state.projects);


  if (uid && projectsListObject?.selectedProject) {
    if (projectsListObject?.selectedProject?.uid !== uid) {
      let projectName = uid ? projectsListObject?.projectsList.filter((obj) => obj.uid === uid)[0]?.projectName : ""
      dispatch(SelectedProjectsInfo({
        uid: uid,
        projectName: projectName ?? "SuperHero"
      }))
    }
  } else if (projectsListObject?.selectedProject?.uid !== "") {
    dispatch(SelectedProjectsInfo({
      uid: "",
      projectName: ""
    }))
  }

  const [userList, setUserList] = useState<any>([])
  const [levelList, setLevelList] = useState(userList)

  const [selectedLevels, setSelectedLevels] = useState<any>([])
  const [alignment, setAlignment] = useState('');


  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken


  useEffect(() => {
    const getAllLevelList = async () => {
      let res: any = {}
      let version = localStorage.getItem('level_version')
      
      res = await postRequest(LEVELS, { "uid": uid }, accessToken, version ?? "");
      
      if (res && isApiCodeSucess(res)) {
        const allLevelAvialable = res.data?.data.all_level_avialable;
        setAlignment(allLevelAvialable ? "All" : "Selected");
        
        const ContentData = res.data?.data.level_list;
        const formattedLevelListContent: any = [];

        ContentData.forEach((x) => {
          if (!x.is_deleted) {
            formattedLevelListContent.push({
              id: x.id,
              characterImage: x.character_image_path ?? "-",
              characterImageName: x.character_image_name ?? "",
              level: x.level_no,
              newLevel: x.is_new_level,
              scaling: x.scaling,
              isActive: x.is_active,
              imageSize: x.image_size
            });
            if (x.limited_level_selected) {
              setSelectedLevels(prev => [...prev, x.id]);
            }
          }
        });
        setUserList(formattedLevelListContent);
      }
    };

    getAllLevelList();
  }, [accessToken, uid]);

  const cardProps = {
    setUpdateValues: setUpdateValues,
    levelList: levelList,
    setLevelList: setLevelList,
    products: userList,
    setProducts: setUserList,
    selectedLevels: selectedLevels,
    setSelectedLevels: setSelectedLevels,
    uid: uid
  }


  const updateIndex = async () => {
    let indexSendForUpdate = []
    updateValues.forEach(x => {
      indexSendForUpdate.push(
        {
          id: x.id,
          index: levelList.findIndex(i => i.id === x.id) + 1
        }
      )
    }
    )

    let requestData = levelIndexUpdate(indexSendForUpdate, uid)

    let response: any = null;

    let version = localStorage.getItem('level_version')

    response = await postRequest(LEVEL_INDEX_UPDATE_API, requestData, accessToken,
      (version ?? "")
    );

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        navigate(0)
      }
    }
  }

  const handleChange = async (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {

    let response: any = null;

    response = await postRequest(UPDATE_LEVELS_AVIALABLE, {
      uid: uid
    }, accessToken,
      (version ?? "")
    );

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        setAlignment(newAlignment)
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Levels </title>
      </Helmet>

      <PageStyles />
      <Container style={{
        marginTop: "-80px"
      }}
      >
        <Stack mb={5} direction={{ xs: 'column', md: 'row' }} alignItems={'center'} justifyContent="space-between">
          <div style={{
            display: "grid",
            gridColumnGap: "1rem",
            gridTemplateColumns: "repeat(2,1fr)",
          }}>
            <Typography style={{fontWeight:500}} variant="h4">
              Levels
            </Typography>
            {
              ((uid) || (version !== "v1")) ?
                <JigsawAppVersion />
                :
                null
            }
          </div>
         
          <Stack direction={{ xs: 'column', md: 'row' }}  spacing={2} justifyContent="space-between">
            {
              localStorage.getItem('level_version') === "v1"
                ?
                ""
                :
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Selected">Selected</ToggleButton>
                    <ToggleButton value="All">All</ToggleButton>
                  </ToggleButtonGroup>

                  <PushPopUpModal project={1} uid={uid} />
                  <Button variant="contained"
                    onClick={() => {
                      if (uid) {
                        navigate(`/dashboard/${uid}/level/create`)
                      } else {
                        navigate('/dashboard/level/create')
                      }
                    }}>
                    Add
                  </Button>
                </>
            }
          </Stack>
        </Stack>

        <div style={{ width: "max-content", margin:"0 auto", marginBottom:24 }}>
              {(updateValues.length > 0) ?
                <SaveCancelButtonGroup
                  onSave={() => {
                    updateIndex()
                  }}
                  onCancel={() => {
                    navigate(0)
                  }} />
                : ""}
            </div>
        <div style={{overflow: "auto" }}>

          <ProductList {...cardProps} />
        </div>
      </Container>
    </>
  );
}

import { S3Client } from "@aws-sdk/client-s3";

export const BASE_URL = process.env.REACT_APP_S3_BASE_PATH;
export const BASE_URL_TL = process.env.REACT_APP_S3_BASE_PATH_TL;
export const BASE_URL_CLONE = process.env.REACT_APP_S3_BASE_PATH_CLONE;
export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
export const accessKeyId = process.env.REACT_APP_S3_ACCESS_ID;
export const secretAccessKey = process.env.REACT_APP_S3_ACCESS_KEY;
export const REGION = process.env.REACT_APP_S3_REGION;


export const s3Client = new S3Client({
  credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
  },
  region: REGION
});

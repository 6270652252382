import { Navigate } from "react-router-dom";
import { getAccessToken } from "../../utils/common-function";

const PrivateRoute = ({ children, ...rest }: any) => {
  const accessToken = getAccessToken()
  // debugger; // eslint-disable-line no-debugger

  if (!localStorage.getItem('level_version')) {
    localStorage.setItem('level_version', 'v3');
  }

  if ((!accessToken) || accessToken === "null") {
    return <Navigate to={{ pathname: "/login" }} />
  }
  return children;
}

export default PrivateRoute
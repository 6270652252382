import { Button } from '@mui/material';

const SaveCancelButtonGroup = (props) => {
  const { onSave, onCancel } = props;
  return (
    <>
      <Button variant="contained" sx={{
        backgroundColor: "Green",
        height: "50px",
        "&:hover": {
          backgroundColor: "#225c22",
          cursor: "pointer",
          height: "50px",
        }
      }}
        onClick={onSave}>
        <span
          style={{
            fontWeight: 600,
            fontSize: "16px"
          }}
        >
          Save
        </span>
      </Button>

      <Button variant="contained" sx={{
        backgroundColor: "red",
        height: "50px",
        ml: 1,
        "&:hover": {
          backgroundColor: "red",
          cursor: "pointer",
          height: "50px",
        }
      }}
        onClick={onCancel}>
        <span
          style={{
            fontWeight: 600,
            fontSize: "16px"
          }}
        >
          Cancel
        </span>
      </Button>
    </>
  )
}

export default SaveCancelButtonGroup
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { postRequest } from '../data/helpers/services';
import { MEDIA_DELETE } from '../constants';
import { S3_BUCKET, s3Client } from "../constants/s3Bucket";

export function ImageUploadDeleteConfirmation(props:any) {
    
  const {
      charId,
      setOpenDelete,
      openDelete,
      levelNo,
      imageObj,
      imageArray,
      setImageArray,
      setImageObj,
      imageType,
      accessToken,
      uid
  } = props;
  const handleClose = () => {
      setOpenDelete({state: false, index: 0});
  };

  const character_image_name = imageObj.media;
  let splitted_image_name = "";
  const lastIndex = character_image_name.lastIndexOf("/");
  const after = character_image_name.slice(lastIndex + 1);
  splitted_image_name = after;

  const deleteConfirmed = async() => {
      
      let key =  `superhero/${imageObj.media}`

      if (uid){
          key = `V3_Clone/${uid}/${imageObj.media}`
      }

      const input = {
          Bucket: S3_BUCKET,
          Key: key
      }
      const command = new DeleteObjectCommand(input);
      await s3Client.send(command);
  
      let updatedImageArray = imageArray.map(obj => {
          if (obj.Id === props.name) {
              obj.uploadStarted = false 
              obj.uploaded = false
              obj.media = ""
              return {...obj}
          };
          return obj;
      })
      setImageArray(updatedImageArray)
      setImageObj({...imageObj, uploadStarted:false, uploaded:false, media:""})

      let media_type = "";
      if (imageType === "FG") {
          media_type = "SH_LF";
      }
      else if (imageType === "BG") {
          media_type = "SH_LB";
      }

      let version = localStorage.getItem('level_version')
      
      const res = await postRequest(
          MEDIA_DELETE,
          {
              "id": charId,
              "media_type": media_type,
              "uid": uid
          },
          accessToken,
          (version ?? "")
      );

      setOpenDelete({state: false, index: 0});
  };
  return (
      <DeleteConfirmationModal
          open={openDelete['state']}
          handleClose={handleClose}
          deleteConfirmed={deleteConfirmed}
          modalTitle={"Delete Confirmation"}
          modalContent={ <span>
              Are you sure you want to delete {imageType}_image{' '}
              <strong>({splitted_image_name})</strong>{' '}
              from index <strong>{levelNo}</strong>?
          </span>}
      />
  )
}
